import React, { useContext } from 'react';
import { FilterContext } from 'screens/Report/utils/FilterContext';

import filter from '../../../model/Filters';
import { ModalContext } from '../utils/ModalContext';
import Posts from './components/Posts';
import RewoundHighlightPostText from './components/RewoundHighlightPostText';

function PostsSentimented({ posts }) {
    const filterContext = useContext(FilterContext);
    const modalContext = useContext(ModalContext);
    const data = filter(posts, filterContext.state);

    return (
        <Posts
            data={data}
            renderPostContent={(post, content) => <RewoundHighlightPostText post={post} content={content} />}
            onTextClick={modalContext.showFileModal}
            sentimentResolver={(post) => post.sentiment}
        />
    );
}

export default PostsSentimented;
