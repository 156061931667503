import * as R from 'ramda';

import { isTokenExpired } from '../../auth/jwt';
import { LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT_SUCCESS } from './actionsTypes';

const unauthenticated = {
    isAuthenticated: false,
    user: {},
};

function computeInitialState() {
    try {
        const user = JSON.parse(localStorage.getItem('user'));
        if (!isTokenExpired(user.access_token)) {
            return {
                isAuthenticated: true,
                user: user,
            };
        }
        return unauthenticated;
    } catch (e) {
        return unauthenticated;
    }
}

export default function auth(state = computeInitialState(), action) {
    switch (action.type) {
        case LOGIN_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                isAuthenticated: false,
            });
        case LOGIN_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                isAuthenticated: true,
                errorMessage: null,
                user: action.user,
            });
        case LOGIN_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                isAuthenticated: false,
                errorMessage: action.message,
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, unauthenticated);
        default:
            return state;
    }
}

export function selectAccessToken(state) {
    return R.path(['auth', 'user', 'access_token'])(state);
}
