import React from 'react';
import { connect, useSelector } from 'react-redux';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';

import Post from './Post';

function Posts({ data, renderPostContent, sentimentResolver, onTextClick }) {
    const projectName = useSelector((state) => state.report.parameters.project);

    const Row = ({ index, style }) => (
        <Post
            key={data[index].id}
            post={data[index]}
            sentiment={sentimentResolver(data[index])}
            renderContent={renderPostContent}
            onTextClick={onTextClick}
            style={style}
            projectName={projectName}
        />
    );
    return (
        <AutoSizer>
            {({ height, width }) => (
                <List height={height} width={width} itemCount={data.length} itemSize={110}>
                    {Row}
                </List>
            )}
        </AutoSizer>
    );
}

export default connect(null, null)(Posts);
