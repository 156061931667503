import React, { Component } from 'react';
import { connect } from 'react-redux';

const AuthComponent = function(ComposedComponent) {
    class Authenticated extends Component {
        componentDidMount() {
            this.redirectIfUnauthenticated();
        }

        componentDidUpdate() {
            this.redirectIfUnauthenticated();
        }

        redirectIfUnauthenticated() {
            if (!this.props.isAuthenticated) {
                this.props.history.push('/login');
            }
        }

        render() {
            return <ComposedComponent {...this.props} />;
        }
    }

    function mapStateToProps(state) {
        return { isAuthenticated: state.auth.isAuthenticated };
    }

    return connect(mapStateToProps)(Authenticated);
};

export default AuthComponent;
