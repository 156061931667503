const options = {
    maintainAspectRatio: false,
    legend: {
        position: 'bottom',
        labels: {
            fontSize: 8,
            fontFamily: 'roboto',
        },
    },
    scales: {
        xAxes: [
            {
                stacked: true,
                gridLines: {
                    display: false,
                },
                ticks: {
                    fontFamily: 'roboto',
                    fontSize: 10,
                },
            },
        ],
        yAxes: [
            {
                stacked: true,
                gridLines: {
                    display: false,
                },
                ticks: {
                    fontFamily: 'roboto',
                    fontSize: 10,
                },
            },
        ],
    },
    tooltips: {
        backgroundColor: 'rgb(255,255,255)',
        borderColor: 'rgb(64,64,64)',
        borderWidth: 1,
        titleFontColor: 'rgb(64,64,64)',
        titleFontSize: 10,
        titleFontFamily: 'roboto',
        bodyFontColor: 'rgb(64,64,64)',
        bodyFontFamily: 'roboto',
        bodyFontSize: 10,
        footerFontColor: 'rgb(64,64,64)',
        footerFontFamily: 'roboto',
        footerFontSize: 10,
    },
};

export default options;
