const IS_COMPARED_AFTER = 1;
const IS_COMPARED_BEFORE = -1;

export const sortByCount_desc = (a, b) => {
    const countResult = a.count > b.count;
    if (a.count === b.count) {
        return a.value.localeCompare(b.value);
    } else {
        return countResult ? IS_COMPARED_BEFORE : IS_COMPARED_AFTER;
    }
};

export const sortByCount_MaxToMin = (a, b) => {
    if (a.count < b.count) {
        return 1;
    }
    if (a.count > b.count) {
        return -1;
    }
    return 0;
};
