import { combineReducers } from 'redux';

import auth from './auth/reducer';
import project from './project/reducer';
import report from './report/reducer';

export default combineReducers({
    report,
    auth,
    project,
});
