import React from 'react';
import { Bar, Line } from 'react-chartjs-2';

export function showSourcesByDate(sources) {
    const options = {
        maintainAspectRatio: false,
        legend: {
            position: 'bottom',
            labels: {
                fontSize: 8,
                fontFamily: 'roboto',
            },
        },
        scales: {
            xAxes: [
                {
                    stacked: true,
                    gridLines: {
                        display: false,
                    },
                    ticks: {
                        fontFamily: 'roboto',
                        fontSize: 10,
                    },
                },
            ],
            yAxes: [
                {
                    stacked: true,
                    gridLines: {
                        display: false,
                    },
                    ticks: {
                        fontFamily: 'roboto',
                        fontSize: 10,
                    },
                },
            ],
        },
        tooltips: {
            backgroundColor: 'rgb(255,255,255)',
            borderColor: 'rgb(64,64,64)',
            borderWidth: 1,
            titleFontColor: 'rgb(64,64,64)',
            titleFontSize: 10,
            titleFontFamily: 'roboto',
            bodyFontColor: 'rgb(64,64,64)',
            bodyFontFamily: 'roboto',
            bodyFontSize: 10,
            footerFontColor: 'rgb(64,64,64)',
            footerFontFamily: 'roboto',
            footerFontSize: 10,
        },
    };
    const uniq = (a) => [...new Set(a)];
    const flatten = (a) => [].concat.apply([], a);
    const dates = sources.map((e) => e.date);
    const names = uniq(flatten(sources.map((e) => e.data)).map((e) => e.source));
    const colors = uniq(flatten(sources.map((e) => e.data)).map((e) => e.color));

    const result = names.map((name) => {
        return {
            names,
            colors,
            data: dates.map((date) => {
                const hit = sources.find((e) => e.date === date).data.find((p) => p.source === name);
                return hit ? hit.count : 0;
            }),
        };
    });

    var datasets = result.map((result, index) => {
        return {
            label: result.names[index],
            maxBarThickness: 40,
            data: result.data,
            backgroundColor: result.colors[index],
        };
    });

    var data = {
        type: 'bar',
        labels: dates,
        datasets: datasets,
    };

    return <Bar data={data} options={options} redraw />;
}

export function showSentimentByDate(sources) {
    const options = {
        maintainAspectRatio: false,
        legend: {
            display: false,
            position: 'bottom',
            labels: {
                fontSize: 10,
                fontFamily: 'roboto',
            },
        },
        scales: {
            xAxes: [
                {
                    stacked: false,
                    gridLines: {
                        display: false,
                    },
                    ticks: {
                        fontFamily: 'roboto',
                        fontSize: 10,
                    },
                },
            ],
            yAxes: [
                {
                    stacked: false,
                    gridLines: {
                        display: false,
                    },
                    ticks: {
                        fontFamily: 'roboto',
                        fontSize: 10,
                    },
                },
            ],
        },
        tooltips: {
            backgroundColor: 'rgb(255,255,255)',
            borderColor: 'rgb(64,64,64)',
            borderWidth: 1,
            titleFontColor: 'rgb(64,64,64)',
            titleFontSize: 10,
            titleFontFamily: 'roboto',
            bodyFontColor: 'rgb(64,64,64)',
            bodyFontFamily: 'roboto',
            bodyFontSize: 10,
            footerFontColor: 'rgb(64,64,64)',
            footerFontFamily: 'roboto',
            footerFontSize: 10,
        },
    };

    const dates = sources.map((e) => e.date);
    const sentiments = ['pozytywny', 'negatywny', 'neutralny', 'nieznany'];
    const colors = ['#5dc24a', '#ff514e', '#79bfd9', '#8a8a8a'];

    const result = sentiments.map((sentiment) => {
        return {
            sentiments,
            colors,
            data: dates.map((date) => {
                const hit = sources.find((e) => e.date === date).data.find((p) => p.sentiment === sentiment);
                return hit ? hit.count : 0;
            }),
        };
    });

    var datasets = result.map((r, index) => {
        return {
            maxBarThickness: 40,
            label: r.sentiments[index],
            data: r.data,
            borderWidth: 1,
            pointBorderWidth: 1,
            pointRadius: 0,
            borderColor: r.colors[index],
            backgroundColor: r.colors[index],
            pointBackgroundColor: r.colors[index],
            pointBorderColor: r.colors[index],
            pointHoverBackgroundColor: r.colors[index],
            pointHoverBorderColor: r.colors[index],
            fill: false,
            lineTension: 0,
        };
    });

    var data = {
        labels: dates,
        datasets: datasets,
    };

    return <Line data={data} options={options} />;
}
