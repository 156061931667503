import React, { Component } from 'react';
import { samplePostsYears, sampleSentimentYears } from 'SampleData';
import { showSentimentByDate, showSourcesByDate } from 'utils/chartPreparation';

import { Chart, ChartArea, ChartBody, ChartBodyLine, ChartTitle } from '../../../shared/style';
import SentimentLegend from '../components/SentimentLegend';

class Sentiments extends Component {
    render() {
        var postsByDate = samplePostsYears;
        var sentimentYears = sampleSentimentYears;
        var postsChart = showSourcesByDate(postsByDate);
        var sentimentsChart = showSentimentByDate(sentimentYears);
        return (
            <ChartArea>
                <Chart>
                    <ChartTitle>Liczba wypowiedzi oceniających z podziałem na lata i źródła</ChartTitle>
                    <ChartBody>{postsChart}</ChartBody>
                </Chart>
                <Chart>
                    <ChartTitle>Wydźwięk wypowiedzi oceniających na osi czasu</ChartTitle>
                    <ChartBodyLine>
                        {sentimentsChart}
                        <SentimentLegend />
                    </ChartBodyLine>
                </Chart>
            </ChartArea>
        );
    }
}

export default Sentiments;
