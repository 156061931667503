export const samplePostsYears = [
    {
        date: '2015-01',
        data: [
            {
                source: 'facebook.com',
                count: 252,
                sentiment: 'pozytywny',
                color: '#081a40',
            },
            {
                source: 'twitter.com',
                count: 11,
                sentiment: 'negatywny',
                color: '#f9ba1f',
            },
            {
                source: 'ceneo.pl',
                count: 5,
                sentiment: 'neutralny',
                color: '#ca044c',
            },
            {
                source: 'ofeminin.pl',
                count: 95,
                sentiment: 'pozytywny',
                color: '#614673',
            },
        ],
    },
    {
        date: '2015-07',
        data: [
            {
                source: 'facebook.com',
                count: 252,
                sentiment: 'pozytywny',
                color: '#081a40',
            },
            {
                source: 'twitter.com',
                count: 11,
                sentiment: 'negatywny',
                color: '#f9ba1f',
            },
            {
                source: 'ceneo.pl',
                count: 5,
                sentiment: 'neutralny',
                color: '#ca044c',
            },
            {
                source: 'ofeminin.pl',
                count: 95,
                sentiment: 'pozytywny',
                color: '#614673',
            },
        ],
    },
    {
        date: '2016-01',
        data: [
            {
                source: 'facebook.com',
                count: 1252,
                sentiment: 'pozytywny',
                color: '#081a40',
            },
            {
                source: 'twitter.com',
                count: 211,
                sentiment: 'negatywny',
                color: '#f9ba1f',
            },
            {
                source: 'ceneo.pl',
                count: 35,
                sentiment: 'neutralny',
                color: '#ca044c',
            },
            {
                source: 'ofeminin.pl',
                count: 995,
                sentiment: 'pozytywny',
                color: '#614673',
            },
        ],
    },
    {
        date: '2016-07',
        data: [
            {
                source: 'facebook.com',
                count: 1252,
                sentiment: 'pozytywny',
                color: '#081a40',
            },
            {
                source: 'twitter.com',
                count: 211,
                sentiment: 'negatywny',
                color: '#f9ba1f',
            },
            {
                source: 'ceneo.pl',
                count: 35,
                sentiment: 'neutralny',
                color: '#ca044c',
            },
            {
                source: 'ofeminin.pl',
                count: 995,
                sentiment: 'pozytywny',
                color: '#614673',
            },
        ],
    },
    {
        date: '2017-01',
        data: [
            {
                source: 'facebook.com',
                count: 232,
                sentiment: 'pozytywny',
                color: '#081a40',
            },
            {
                source: 'twitter.com',
                count: 2,
                sentiment: 'negatywny',
                color: '#f9ba1f',
            },
            {
                source: 'ceneo.pl',
                count: 95,
                sentiment: 'neutralny',
                color: '#ca044c',
            },
            {
                source: 'vitalia.pl',
                count: 335,
                sentiment: 'pozytywny',
                color: '#01aa8d',
            },
        ],
    },
    {
        date: '2017-07',
        data: [
            {
                source: 'facebook.com',
                count: 232,
                sentiment: 'pozytywny',
                color: '#081a40',
            },
            {
                source: 'twitter.com',
                count: 2,
                sentiment: 'negatywny',
                color: '#f9ba1f',
            },
            {
                source: 'ceneo.pl',
                count: 95,
                sentiment: 'neutralny',
                color: '#ca044c',
            },
            {
                source: 'vitalia.pl',
                count: 335,
                sentiment: 'pozytywny',
                color: '#01aa8d',
            },
        ],
    },
];

export const sampleSentimentYears = [
    {
        date: '2015-01',
        data: [
            {
                count: 252,
                sentiment: 'pozytywny',
            },
            {
                count: 11,
                sentiment: 'negatywny',
            },
            {
                count: 5,
                sentiment: 'neutralny',
            },
            {
                count: 95,
                sentiment: 'nieznany',
            },
        ],
    },
    {
        date: '2015-07',
        data: [
            {
                count: 252,
                sentiment: 'pozytywny',
            },
            {
                count: 11,
                sentiment: 'negatywny',
            },
            {
                count: 5,
                sentiment: 'neutralny',
            },
            {
                count: 95,
                sentiment: 'nieznany',
            },
        ],
    },
    {
        date: '2016-01',
        data: [
            {
                count: 1252,
                sentiment: 'pozytywny',
            },
            {
                count: 211,
                sentiment: 'negatywny',
            },
            {
                count: 35,
                sentiment: 'neutralny',
            },
            {
                count: 995,
                sentiment: 'nieznany',
            },
        ],
    },
    {
        date: '2016-07',
        data: [
            {
                count: 1252,
                sentiment: 'pozytywny',
            },
            {
                count: 211,
                sentiment: 'negatywny',
            },
            {
                count: 35,
                sentiment: 'neutralny',
            },
            {
                count: 995,
                sentiment: 'nieznany',
            },
        ],
    },
    {
        date: '2017-01',
        data: [
            {
                count: 232,
                sentiment: 'pozytywny',
            },
            {
                count: 2,
                sentiment: 'negatywny',
            },
            {
                count: 95,
                sentiment: 'neutralny',
            },
            {
                count: 335,
                sentiment: 'nieznany',
            },
        ],
    },
    {
        date: '2017-07',
        data: [
            {
                count: 232,
                sentiment: 'pozytywny',
            },
            {
                count: 2,
                sentiment: 'negatywny',
            },
            {
                count: 95,
                sentiment: 'neutralny',
            },
            {
                count: 335,
                sentiment: 'nieznany',
            },
        ],
    },
];
