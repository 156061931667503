import TextField from '@material-ui/core/TextField';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import style from './style.module.scss';

const FullSearch = ({ handleSearchByAnyText }) => {
    const { t } = useTranslation();
    const [value, setValue] = useState('');

    const handleChange = (e) => {
        const value = e.target.value;
        setValue(value);
        handleSearchByAnyText(value);
    };

    return (
        <TextField
            type="text"
            name="text"
            label={t('postsList.search')}
            onChange={handleChange}
            value={value}
            className={style.wrapper}
        />
    );
};

export default FullSearch;
