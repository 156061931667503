import { sortByCount_desc } from 'utils/sorting';

const POSITIVE = 'pozytywny';
const NEGATIVE = 'negatywny';

let modifierWordForSentiment_Cache = {};
let sentiments_Cache = [];

const extractOpinion = (allOpinionsWithSentiment, type) =>
    allOpinionsWithSentiment.filter((el) => el.sentiment === type).filter((opinion) => !!opinion.modifierWord);

const getCachedModifierWords = (data, sentiment) => {
    if (!modifierWordForSentiment_Cache[sentiment]) {
        const allOpinionsWithSentiment = data.filter((el) => el.sentimentWord === sentiment);
        const positive = extractOpinion(allOpinionsWithSentiment, POSITIVE).sort(sortByCount_desc);
        const negative = extractOpinion(allOpinionsWithSentiment, NEGATIVE).sort(sortByCount_desc);
        modifierWordForSentiment_Cache[sentiment] = { positive, negative };
    }
    return modifierWordForSentiment_Cache[sentiment];
};

export const prepareModifierWordsForSentiment = (data, sentiment) => getCachedModifierWords(data, sentiment);

export const cleanModifierWordCache = () => {
    sentiments_Cache = [];
    modifierWordForSentiment_Cache = [];
};

const reduceDataOnlyWithNonNeutralSentiment = (prev, el, counts, data) => {
    const sentiment = el.sentimentWord;
    const { positive, negative } = prepareModifierWordsForSentiment(data, sentiment);

    if (positive && negative && (positive.length || negative.length)) {
        counts[sentiment] = (counts[sentiment] || 0) + el.count;
        prev.push(sentiment);
    }

    return prev;
};

export const extractDataToDisplay = (data) => {
    if (sentiments_Cache.length < 1) {
        const counts = {};
        const sentiments = data.reduce((prev, el) => reduceDataOnlyWithNonNeutralSentiment(prev, el, counts, data), []);

        const uniqueSentiments = new Set(sentiments);

        const result = Array.from(uniqueSentiments).map((sentiment) => ({
            value: sentiment,
            count: counts[sentiment],
        }));

        result.sort(sortByCount_desc);
        sentiments_Cache = result;
    }

    return sentiments_Cache;
};
