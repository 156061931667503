import React, { useContext } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

import { ModalContext } from '../ModalContext';
import style from './style.module.scss';

ReactModal.setAppElement('#root');

export const Modal = () => {
    const { t } = useTranslation();
    const modalContext = useContext(ModalContext);
    const [showToast, setShowToast] = useState(false);

    const copyToClipBoard = async () => {
        await navigator.clipboard.writeText(modalContext.state.modalText);
        setShowToast(true);
    };

    const onClose = () => {
        setShowToast(false);
        modalContext.reset();
    };

    return (
        <ReactModal
            isOpen={modalContext.state.showModal}
            className={style['modal']}
            overlayClassName={style['modal-overlay']}
            onRequestClose={modalContext.reset}
        >
            <h2 className={style['modal__header']}>{modalContext.state.modalHeader}</h2>
            <p className={style['modal__text']}>{modalContext.state.modalText}</p>
            <div className={style['modal__button-wrapper']}>
                <button onClick={copyToClipBoard} className={style['modal__button__copy']}>
                    {t('postsListModal.copy')}
                    <i className="far fa-copy" />
                </button>
                <button onClick={onClose} className={style['modal__button']}>
                    {t('postsListModal.close')}
                    <i className="fas fa-times" />
                </button>
            </div>

            {showToast && <div className={style['CopiedText']}>{t('postsList.toast')}</div>}
        </ReactModal>
    );
};
