import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FilterContext } from 'screens/Report/utils/FilterContext';
import { Chart, ChartTitle } from 'screens/shared/style';
import { findAspectsSortedByCount } from 'utils/postsTransformers';

import CommentTooltip from '../components/CommentTooltip';
import Selector from '../components/Selector';

const TopicList = () => {
    const { t } = useTranslation();
    const posts = useSelector((state) => state.report.posts);
    const filterContext = useContext(FilterContext);

    const onClick = ({ value }) => {
        filterContext.reset();
        filterContext.setAspect(value);
    };

    const data = useMemo(() => findAspectsSortedByCount(posts || []), [posts]);

    const collapseData = [
        {
            type: 'neutral',
            header: t('topics.topicList.topics'),
            data,
            onClick,
        },
    ];

    return (
        <Chart>
            <ChartTitle>
                {t('topics.topicList.title')}
                <CommentTooltip> {t('topics.topicList.comment')}</CommentTooltip>
            </ChartTitle>
            <Selector data={collapseData} />
        </Chart>
    );
};

export default TopicList;
