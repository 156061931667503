import { mapSourcesByDate, prepareDatesLabels } from '../chartHelpers';
import { countSources, prepareDataToDisplayForChart } from './helpers';

const MAX_MONTHS = 12;

export const showSourcesByYearAndSource = (sourcePosts) => {
    const years = prepareDatesLabels(sourcePosts, 'year');
    const mappedSources = mapSourcesByDate(sourcePosts, years, 'year');
    const countedSources = countSources(mappedSources);

    return prepareDataToDisplayForChart(countedSources, sourcePosts);
};

const mapSourceByMonthIsideYear = (mappedByYear) =>
    mappedByYear.map((byYear) => {
        const months = {};
        for (let i = 1; i <= MAX_MONTHS; i++) {
            months[i] = byYear.data.filter((el) => parseInt(el.month, 10) === i);
        }
        return {
            date: byYear.date,
            data: months,
        };
    });

const mapSourcesByYearAndMonth = (mappedByYear) => {
    const byMonthInsideYear = mapSourceByMonthIsideYear(mappedByYear);
    const result = [];

    byMonthInsideYear.forEach((el) => {
        const monthNumbers = Object.keys(el.data);
        const year = el.date;

        monthNumbers.forEach((monthNumber) => {
            const data = el.data[monthNumber];
            if (data.length > 0) {
                result.push({
                    date: `${year}-${monthNumber}`,
                    data,
                });
            }
        });
    });

    return result;
};

export const showSourcesByMonthAndYearAndSource = (sourcePosts) => {
    const years = prepareDatesLabels(sourcePosts, 'year');
    const mappedSources = mapSourcesByDate(sourcePosts, years, 'year');
    const mappedSourcesByYearAndMonth = mapSourcesByYearAndMonth(mappedSources);
    const countedSources = countSources(mappedSourcesByYearAndMonth);

    return prepareDataToDisplayForChart(countedSources, sourcePosts);
};
