import React, { useState } from 'react';

import CollapseList from './CollapseList';
import style from './style.module.scss';

const Selector = ({ data }) => {
    const [openId, setOpenId] = useState('');

    return (
        <div className={style['SelectList__area']}>
            {data.map((props, index) => (
                <CollapseList {...props} key={index} openId={openId} setOpenId={setOpenId} />
            ))}
        </div>
    );
};

export default Selector;
