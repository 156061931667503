import { mapSourcesByDate, prepareDatesLabels } from '../chartHelpers.js';

const sentiments = ['pozytywny', 'negatywny', 'neutralny', 'nieznany'];
const colors = ['#5dc24a', '#ff514e', '#79bfd9', '#8a8a8a'];

export const DATE_SOURCE = {
    DAY: 'day',
    MONTH: 'month',
    YEAR: 'year',
};

const DATE_PERIOD_MAP = new Map([
    ['day', 'dzień'],
    ['month', 'miesiąc'],
    ['year', 'rok'],
]);

export const SENTIMENTS = {
    POSITIVE: 'pozytywny',
    NEGATIVE: 'negatywny',
    NEUTRAL: 'neutralny',
    UNKNOWN: 'nieznany',
};

const prepareDataSets = (data) =>
    data.map((r, index) => ({
        label: sentiments[index],
        data: r,
        borderWidth: 1,
        pointBorderWidth: 1,
        maxBarThickness: 40,
        // pointRadius: 0,
        borderColor: colors[index],
        backgroundColor: colors[index],
        pointBackgroundColor: colors[index],
        pointBorderColor: colors[index],
        pointHoverBackgroundColor: colors[index],
        pointHoverBorderColor: colors[index],
        fill: false,
        lineTension: 0,
    }));

const groupBySentiment = (dates, sources) => {
    const counterInitial = {
        [SENTIMENTS.POSITIVE]: 0,
        [SENTIMENTS.NEGATIVE]: 0,
        [SENTIMENTS.NEUTRAL]: 0,
        [SENTIMENTS.UNKNOWN]: 0,
    };

    const sortedData = {
        [SENTIMENTS.POSITIVE]: [],
        [SENTIMENTS.NEGATIVE]: [],
        [SENTIMENTS.NEUTRAL]: [],
        [SENTIMENTS.UNKNOWN]: [],
    };

    let counter = { ...counterInitial };

    dates.forEach((d) => {
        sources.forEach((source) => {
            source.date === d && source.data.forEach((s) => (counter[s.sentiment] = counter[s.sentiment] + 1));
        });

        Object.keys(SENTIMENTS).forEach((key) => sortedData[SENTIMENTS[key]].push(counter[SENTIMENTS[key]]));
        counter = { ...counterInitial };
    });

    return sortedData;
};

const prepareResultData = (dates, sortedData) =>
    sentiments.map((sentiment) => dates.map((date, index) => sortedData[sentiment][index]));

const chooseDateKey = (posts) => {
    const MIN_LENGTH = 1;
    const years = Array.from(new Set(posts.map((post) => post.year)));
    const months = Array.from(new Set(posts.map((post) => post.month)));
    const monthOrDay = months.length > MIN_LENGTH ? DATE_SOURCE.MONTH : DATE_SOURCE.DAY;

    return {
        dateKey: years.length > MIN_LENGTH ? DATE_SOURCE.YEAR : monthOrDay,
        years,
        months,
    };
};

const filterPostsByFilter = (sources, { key, value }) => sources.filter((s) => s[key] === value);

export const prepareTopicSentimentByDate = (rawSources, filter, forceDateKey) => {
    const sources = filterPostsByFilter(rawSources, filter);
    const { dateKey, years, months } = forceDateKey || chooseDateKey(sources);
    const dates = prepareDatesLabels(sources, dateKey);
    const preparedData = mapSourcesByDate(sources, dates, dateKey);
    const sortedData = groupBySentiment(dates, preparedData);
    const resultData = prepareResultData(dates, sortedData);
    const monthOrDayFull =
        dateKey === DATE_SOURCE.MONTH
            ? `${DATE_PERIOD_MAP.get(DATE_SOURCE.MONTH)} w roku ${years[0]}`
            : `${DATE_PERIOD_MAP.get(DATE_SOURCE.DAY)} (miesiąc: ${months[0]}/${years[0]})`;

    return {
        labels: dates,
        datasets: prepareDataSets(resultData),
        dateKey: dateKey === DATE_SOURCE.YEAR ? DATE_PERIOD_MAP.get(dateKey) : monthOrDayFull,
        rawDateKey: dateKey,
    };
};
