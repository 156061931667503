import React from 'react';
import { ChartArea } from 'screens/shared/style';

import Source from './Source';

const Sources = () => (
    <ChartArea>
        <Source />
    </ChartArea>
);

export default Sources;
