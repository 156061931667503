import React from 'react';
import { useTranslation } from 'react-i18next';

import style from './style.module.scss';

const FetchTextButton = ({ onClick }) => {
    const { t } = useTranslation();

    return <i className={`${style['TextButton__icon']} far fa-eye`} onClick={onClick} title={t('postsList.show')} />;
};

export default FetchTextButton;
