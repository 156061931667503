const calendarLabel = 'Kalendarz';
const roleDescription = 'datepicker';
const closeDatePicker = 'Zamknij';
const focusStartDate = 'Podaj datę początkową';
const clearDates = 'Wyczyść';
const jumpToPrevMonth = 'Poprzedni';
const jumpToNextMonth = 'Następny';
const keyboardShortcuts = 'Skróty klawiszowe';
const showKeyboardShortcutsPanel = 'Otwórz skróty klawiszowe';
const hideKeyboardShortcutsPanel = 'Zamknij skróty klawiszowe';
const openThisPanel = 'Otwórz panel.';
const enterKey = 'Enter';
const leftArrowRightArrow = 'Strzałki w lewo i w prawo';
const upArrowDownArrow = 'do góry i w dół';
const pageUpPageDown = 'page up, page down';
const homeEnd = 'Home, end';
const escape = 'Escape';
const questionMark = 'Znak zapytania';
const selectFocusedDate = 'Zatwierdź wybraną datę';
const moveFocusByOneDay = 'Do tyłu i do przodu o jeden dzień.';
const moveFocusByOneWeek = 'Do tyłu i do przodu o tydzień.';
const moveFocusByOneMonth = 'Zmień miesiąc.';
const moveFocustoStartAndEndOfWeek = 'Pierwszy i ostatni dzień tygodnia.';
const returnFocusToInput = 'Wróc do zatwierdzonej daty.';
const keyboardForwardNavigationInstructions = 'Wybierz datę. Znak zapytania - pomoc.';
const keyboardBackwardNavigationInstructions = 'Wybierz datę. Znak zapytania - pomoc.';

const chooseAvailableStartDate = ({ date }) => `Wybierz ${date} datę początkową.`;
const chooseAvailableEndDate = ({ date }) => `Wybierz ${date} datę końcową.`;
const dateIsUnavailable = ({ date }) => `Niedostępne. ${date}`;
const dateIsSelected = ({ date }) => `Wybrano. ${date}`;
const dateIsSelectedAsStartDate = ({ date }) => `Wybrano datę początkową. ${date}`;
const dateIsSelectedAsEndDate = ({ date }) => `Wybrano datę końcową. ${date}`;

export const startDatePlaceholderText = 'od';
export const endDatePlaceholderText = 'do';

export const DateRangePickerPhrases = {
    calendarLabel,
    roleDescription,
    closeDatePicker,
    clearDates,
    focusStartDate,
    jumpToPrevMonth,
    jumpToNextMonth,
    keyboardShortcuts,
    showKeyboardShortcutsPanel,
    hideKeyboardShortcutsPanel,
    openThisPanel,
    enterKey,
    leftArrowRightArrow,
    upArrowDownArrow,
    pageUpPageDown,
    homeEnd,
    escape,
    questionMark,
    selectFocusedDate,
    moveFocusByOneDay,
    moveFocusByOneWeek,
    moveFocusByOneMonth,
    moveFocustoStartAndEndOfWeek,
    returnFocusToInput,
    keyboardForwardNavigationInstructions,
    keyboardBackwardNavigationInstructions,
    chooseAvailableStartDate,
    chooseAvailableEndDate,
    dateIsUnavailable,
    dateIsSelected,
    dateIsSelectedAsStartDate,
    dateIsSelectedAsEndDate,
};
