import * as R from 'ramda';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ReactSelect from 'screens/Report/Main/components/ReactSelector';

import appTheme from '../../../../../public/styles/_theme.scss';
import { FilterContext } from '../../../utils/FilterContext';
import style from './style.module.scss';

const customStyles = {
    option: (provided) => ({
        ...provided,
        borderBottom: '1px dotted',
        fontSize: appTheme.fontSize,
        cursor: 'pointer',
    }),
    control: (provided) => ({
        ...provided,
        fontSize: appTheme.fontSize,
        height: '46px',
        borderStyle: 'none',
        borderBottom: '2px',
        borderBottomColor: 'transparent',
        cursor: 'pointer',
    }),
    singleValue: (provided, state) => ({
        ...provided,
        opacity: state.isDisabled ? 0.5 : 1,
        transition: 'opacity 300ms',
        color: appTheme.colorGreyDarkDark,
    }),
};

const customTheme = (theme) => ({
    ...theme,
    borderRadius: 0,
    fontFamily: appTheme.fontFamily,
    colors: {
        ...theme.colors,
        primary25: appTheme.colorGreyLighter,
        primary: appTheme.colorGreen,
    },
});

const TopicSelectorPanel = () => {
    const { t } = useTranslation();
    const filterContext = useContext(FilterContext);
    const topics = useSelector((state) => state.report.topics);
    const options = R.prepend(
        { value: null, label: t('postsList.all') },
        topics.map((topic) => ({ value: topic, label: topic })),
    );
    const selectedOption = options.find((option) => option.value === filterContext.state.topic);

    if (!topics.length) {
        return null;
    }

    return (
        <div className={style['TopicSelectorPanel__container']}>
            <ReactSelect
                value={selectedOption}
                onChange={(option) => filterContext.setTopic(option.value)}
                options={options}
                placeholder={t('postsList.selectSubject')}
                theme={customTheme}
                styles={customStyles}
            />
        </div>
    );
};

export default TopicSelectorPanel;
