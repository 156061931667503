import { useContext } from 'react';
import { ChartArea } from 'screens/shared/style';

import { FilterContext } from '../../utils/FilterContext';
import OpinionCloud from './OpinionCloud';
import OpinionSelector from './OpinionSelector';
import OpinionWithTopics from './OpinionWithTopics';

const Opinions = () => {
    const filterContext = useContext(FilterContext);

    return (
        <ChartArea>
            <OpinionCloud />
            <OpinionWithTopics opinion={filterContext.state.opinionIncludingModifier} />
            <OpinionSelector />
        </ChartArea>
    );
};

export default Opinions;
