import cn from 'classnames/bind';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getBundle } from '../../../../api/bundles';
import style from './style.module.scss';

const DISABLE_DOWNLOAD_USERNAMES = ['klient40'];

const FileDownload = require('js-file-download');

const triggerZipFileDownload = (project) =>
    getBundle(project)
        .then((response) => response.blob())
        .then((blob) => FileDownload(blob, `${project}.tsv.zip`));

const MenuExport = () => {
    const { t } = useTranslation();
    const { user } = useSelector((state) => state.auth);
    const { project } = useSelector((state) => state.report.parameters);

    const isUserWithDisabledDownload = DISABLE_DOWNLOAD_USERNAMES.includes(user.username);
    const className = cn(style.link, { [style.disabled]: isUserWithDisabledDownload });
    const onClick = () => !isUserWithDisabledDownload && triggerZipFileDownload(project);

    return (
        <div className={className} onClick={onClick} title={t('menu.download')}>
            <i className="fas fa-file-download" />
        </div>
    );
};

export default MenuExport;
