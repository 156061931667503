import PaginatedComponent from '../components/PaginatedComponent';
import style from './style.module.scss';

const Content = ({ sources }) => (
    <ul className={style['Source__total-wrapper']}>
        {sources.map((source) => (
            <li key={source.name} className={style['Source__total-specific']}>
                <span className={source.category + 'Sources'}>{source.name} </span>
                <span className={style['Source__total-specific__count']}>{source.count}</span>
            </li>
        ))}
    </ul>
);

const LIMIT = 10;

const ExtraContent = ({ data }) =>
    data.length > LIMIT ? (
        <PaginatedComponent
            className={style.pagination}
            data={data}
            limit={LIMIT}
            render={(paginatedData) => <Content sources={paginatedData} />}
        />
    ) : (
        <Content sources={data} />
    );

export default ExtraContent;
