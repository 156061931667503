import React, { useContext } from 'react';
import { FilterContext } from 'screens/Report/utils/FilterContext';
import { ChartArea } from 'screens/shared/style';

import TopicCloud from './TopicCloud';
import TopicList from './TopicList';
import TopicSentiment from './TopicSentiment';
import TopicSentimentTime from './TopicSentimentTime';
import TopicWithOpinions from './TopicWithOpinions';

const Aspects = () => {
    const context = useContext(FilterContext);

    return (
        <ChartArea>
            <TopicCloud />
            <TopicSentiment aspect={context.state.aspect} />
            <TopicWithOpinions aspect={context.state.aspect} />
            <TopicList />
            <TopicSentimentTime aspect={context.state.aspect} />
        </ChartArea>
    );
};

export default Aspects;
