import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import style from './style.module.scss';

const MenuBack = () => {
    const { t } = useTranslation();

    return (
        <NavLink to="/browser" className={`${style.link} `} title={t('menu.projectsList')}>
            <i className="fas fa-list-ul" />
        </NavLink>
    );
};

export default MenuBack;
