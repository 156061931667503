import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import styles from './style.module.scss';

const activeClassName = styles['menu-list__element--active'];

const InnerList = ({ children }) => <li className={styles['menu-list__element']}>{children}</li>;

const MenuList = () => {
    const { t } = useTranslation();

    return (
        <ul className={styles['menu-list']}>
            <InnerList>
                <NavLink activeClassName={activeClassName} to="/report/sources">
                    {t('menu.textsAndSources')}
                </NavLink>
            </InnerList>
            <InnerList>
                <NavLink activeClassName={activeClassName} to="/report/aspectBases">
                    {t('menu.topics')}
                </NavLink>
            </InnerList>
            <InnerList>
                <NavLink activeClassName={activeClassName} to="/report/opinions">
                    {t('menu.opinions')}
                </NavLink>
            </InnerList>
            <InnerList>
                <NavLink activeClassName={activeClassName} to="/report/tree">
                    {t('menu.opinionsTree')}
                </NavLink>
            </InnerList>
        </ul>
    );
};

export default MenuList;
