import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import style from './style.module.scss';

const CopyTextButton = ({ onFetch }) => {
    const { t } = useTranslation();

    return (
        <i
            className={`${style['TextButton__icon']} ${style['CopyButton']} far fa-copy`}
            onClick={onFetch}
            title={t('postsList.copy')}
        />
    );
};

CopyTextButton.propTypes = {
    onFetch: PropTypes.func.isRequired,
};

export default CopyTextButton;
