import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import Pagination from './Pagination';

const FIRST_PAGE = 1;
const LIMIT = 6;
const STEP = 1;

const PaginatedComponent = ({ data, refresh, render, limit = LIMIT, className = '' }) => {
    const pageCount = data.length / limit;
    const [dataSlice, setDataSlice] = useState([]);
    const [currentPage, setCurrentPage] = useState(FIRST_PAGE);

    const goToPage = useCallback(
        (page) => {
            page -= STEP;
            const offset = Math.ceil(page * limit);
            const newSlice = data.slice(offset, offset + limit);
            setCurrentPage(page + STEP);
            setDataSlice(newSlice);
        },
        [data, limit],
    );

    /*eslint-disable */
    useEffect(() => {
        goToPage(FIRST_PAGE);
    }, [JSON.stringify(data), refresh]);
    /*eslint-enable */

    return (
        <>
            {render(dataSlice)}

            <Pagination
                className={className}
                currentPage={currentPage}
                totalPages={Math.ceil(pageCount)}
                onPageChange={goToPage}
            />
        </>
    );
};

PaginatedComponent.propTypes = {
    className: PropTypes.string,
    data: PropTypes.array.isRequired,
    refresh: PropTypes.object,
    render: PropTypes.func.isRequired,
    limit: PropTypes.number,
};

export default PaginatedComponent;
