import React, { Component } from 'react';

export const ModalContext = React.createContext(null);

export class ModalProvider extends Component {
    initialState = {
        showModal: false,
        modalText: '',
        modalHeader: '',
    };

    state = { ...this.initialState };

    render() {
        return (
            <ModalContext.Provider
                value={{
                    state: this.state,
                    showFileModal: (text) =>
                        this.setState({
                            showModal: true,
                            modalHeader: 'Pełny tekst',
                            modalText: text,
                        }),
                    reset: () => this.setState({ ...this.initialState }),
                }}
            >
                {this.props.children}
            </ModalContext.Provider>
        );
    }
}
