import React from 'react';
import { useTranslation } from 'react-i18next';

import style from './style.module.scss';

const LegendNegative = () => {
    const { t } = useTranslation();
    return <span className={style['Legend--negative']}>{t('negative')}</span>;
};
export default LegendNegative;
