import { useEffect } from 'react';
import { useRef } from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import { Route, Switch } from 'react-router-dom';

import { FilterContext } from '../utils/FilterContext';
import PostsTitle from './components/PostsTitle';
import FlatPosts from './FlatPosts';
import PostsOpinions from './PostsOpinions';
import PostsSentimented from './PostsSentimented';
import style from './style.module.scss';

const EXCLUDE_KEYS_TO_SEARCH = ['id'];

const getMatchedPosts = (text, posts) =>
    posts.filter((post) => {
        const keys = Object.keys(post);
        const search = text.toLowerCase();
        const keysWithoutExcluded = keys.filter((key) => !EXCLUDE_KEYS_TO_SEARCH.includes(key));
        return keysWithoutExcluded.some((key) => (post[key] + '').toLowerCase().includes(search));
    });

const PostsList = ({ height }) => {
    const [topHeight, setTopHeight] = useState(0);
    const [postsToDisplay, setPostsToDisplay] = useState([]);
    const posts = useSelector((state) => state.report.posts);
    const context = useContext(FilterContext);
    const topContainerRef = useRef();

    useEffect(() => {
        context.reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleReportContentHeightChange = () => {
        if (topContainerRef) {
            const data = topContainerRef.clientHeight;
            setTopHeight(data);
        }
    };

    const handleSearchByAnyText = (searchValue) => {
        const data = getMatchedPosts(searchValue, posts);
        setPostsToDisplay(data);
    };

    const maxPostsHeight = height - topHeight;
    const inlineStyle = { maxHeight: `${maxPostsHeight}px`, height: `${maxPostsHeight}px` };
    const displayPosts = postsToDisplay.length ? postsToDisplay : posts;

    return (
        <div className={style['main-right-container']}>
            <div
                ref={topContainerRef}
                style={{
                    flex: '1',
                    display: 'flex',
                    padding: '.5rem',
                }}
            >
                <PostsTitle handleSearchByAnyText={handleSearchByAnyText} />
                <ReactResizeDetector handleHeight onResize={handleReportContentHeightChange} />
            </div>
            <div className={style['fixed-container']} style={inlineStyle}>
                <div className={style['container']}>
                    <Switch>
                        <Route exact path="/report/sources" render={() => <FlatPosts posts={displayPosts} />} />
                        <Route
                            exact
                            path="/report/sentiments"
                            render={() => <PostsSentimented posts={displayPosts} />}
                        />
                        <Route
                            exact
                            path="/report/aspectBases"
                            render={() => <PostsSentimented posts={displayPosts} />}
                        />
                        <Route exact path="/report/opinions" render={() => <PostsOpinions posts={displayPosts} />} />
                        <Route exact path="/report/tree" render={() => <PostsOpinions posts={postsToDisplay} />} />
                    </Switch>
                </div>
            </div>
        </div>
    );
};

export default PostsList;
