import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FilterContext } from 'screens/Report/utils/FilterContext';
import { Chart, ChartTitle } from 'screens/shared/style';

import CommentTooltip from '../components/CommentTooltip';
import Selector from '../components/Selector';
import { extractAllOpinion_sentimentWord } from '../components/Selector/listsPreparation';
import { extractDataToDisplay, prepareModifierWordsForSentiment } from './helpers';
import OpinionCircles from './OpinionCircles';
import OpinionTreePanel from './OpinionTreePanel';
import style from './style.module.scss';

const OpinionTree = () => {
    const { t } = useTranslation();
    const posts = useSelector((state) => state.report.posts);
    const data = extractAllOpinion_sentimentWord(posts);
    const [positiveData, setPositiveData] = useState([]);
    const [negativeData, setNegativeData] = useState([]);
    const [selectedSentimentWord, setSentimentSelectedWord] = useState('');
    const filterContext = useContext(FilterContext);

    const onClick = (entry) => {
        const sentiment = entry.value;
        const { positive, negative } = prepareModifierWordsForSentiment(data, sentiment);

        if (positive && negative) {
            setPositiveData(positive);
            setNegativeData(negative);
            setSentimentSelectedWord(entry.value);
        }
    };

    const collapseData = [
        {
            type: 'unknown',
            header: t('opinionsTree.selector'),
            data: extractDataToDisplay(data),
            onClick: onClick,
        },
    ];

    const onClickModifierWord = (el) => {
        const opinion = {
            modifierWord: el.modifierWord,
            sentimentWord: el.sentimentWord,
            sentiment: el.sentiment,
            phrase: el.value,
        };
        filterContext.setOpinionIncludingModifier(opinion);
    };

    return (
        <Chart>
            <ChartTitle>
                {t('opinionsTree.title')}
                <CommentTooltip> {t('opinionsTree.comment')}</CommentTooltip>
            </ChartTitle>
            <Selector data={collapseData} />

            <div className={style['OpinionTree__sentiment']}>{selectedSentimentWord}</div>

            {selectedSentimentWord && (
                <>
                    <OpinionCircles positiveLength={positiveData.length} negativeLength={negativeData.length} />
                    <div className={style['OpinionTree__sentiments-wrapper']}>
                        <OpinionTreePanel type="positive" data={positiveData} onClick={onClickModifierWord} />
                        <OpinionTreePanel type="negative" data={negativeData} onClick={onClickModifierWord} />
                    </div>
                </>
            )}
        </Chart>
    );
};

export default OpinionTree;
