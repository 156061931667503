import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { usePostsByDate } from 'screens/Report/utils/postDatesHooks';
import { Chart, ChartTitle } from 'screens/shared/style';
import { prepareTopicsForOpinion } from 'utils/graphPreparation';

import Balls from '../components/Balls';
import { ExportImgButtonForChart } from '../components/ExportImgButton';
import LegendNegative from '../components/legends/LegendNegative';
import LegendNeutral from '../components/legends/LegendNeutral';
import LegendPositive from '../components/legends/LegendPositive';
import PaginatedComponent from '../components/PaginatedComponent';
import SentimentLegend from '../components/SentimentLegend';

const Legend = () => (
    <SentimentLegend>
        <LegendPositive />
        <LegendNegative />
        <LegendNeutral />
    </SentimentLegend>
);

const OpinionWithTopics = ({ opinion }) => {
    const { t } = useTranslation();
    const componentRef = useRef();
    const posts = useSelector((state) => state.report.posts);
    const postsByDate = usePostsByDate(posts);
    const data = opinion ? prepareTopicsForOpinion(postsByDate, opinion) : null;
    const opinionToShow = (opinion && opinion.phrase) || '';

    const isDataAvailable = data && data.length > 0;

    const filterTextsByOpinion = (filterContext, entry) => {
        filterContext.reset();
        filterContext.setOpinionIncludingModifier(opinion);
        filterContext.setAspect(entry.aspectBase);
    };

    return (
        <Chart>
            <div className="bg-white" ref={componentRef}>
                <ChartTitle>
                    {isDataAvailable ? t('opinions.sentiments.sentiments') : t('opinions.sentiments.noSentiments')}{' '}
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t('opinions.sentiments.forOpinion', { opinionToShow }),
                        }}
                    />
                </ChartTitle>
                <div>
                    {isDataAvailable ? (
                        <>
                            <PaginatedComponent
                                data={data}
                                render={(paginatedData) => (
                                    <Balls
                                        data={paginatedData}
                                        getLabel={(data) => data.label}
                                        refresh={opinion.phrase}
                                        centralNodeText={opinion.phrase}
                                        centralNodeSentiment={opinion.sentiment}
                                        useBlankBallsAroundCenter
                                        onClick={filterTextsByOpinion}
                                    />
                                )}
                            />
                            <Legend />
                        </>
                    ) : null}
                </div>
            </div>
            <ExportImgButtonForChart componentRef={componentRef} />
        </Chart>
    );
};

export default OpinionWithTopics;
