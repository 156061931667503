import React from 'react';
import { TextFilter } from 'react-text-filter';

import style from './style.module.scss';

const ListFilter = ({ onFilter }) => (
    <TextFilter
        onFilter={({ target: { value: filter } }) => onFilter(filter)}
        placeholder="Szukaj..."
        className={style['list-filter']}
    />
);

export default ListFilter;
