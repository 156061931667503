import { useTranslation } from 'react-i18next';
import ReactSelect from 'screens/Report/Main/components/ReactSelector';

const OPTIONS = [
    { value: 'pl', label: 'polski' },
    { value: 'fr', label: 'français' },
];

const LanguageSelector = () => {
    const { t, i18n } = useTranslation();

    const onClick = (lang) => i18n.changeLanguage(lang.value);

    const getSelected = () => OPTIONS.find((el) => el.value === i18n.language);

    return (
        <>
            <small>{t('menu.selectLanguage')}:</small>
            <ReactSelect value={getSelected()} onChange={onClick} options={OPTIONS} />
        </>
    );
};

export default LanguageSelector;
