import React from 'react';
import { HorizontalBar } from 'react-chartjs-2';

import { countData, prepareDatasets } from './helpers';
import { getOptions } from './options';

const sentiments = ['pozytywny', 'negatywny', 'neutralny', 'nieznany'];
const colors = ['#ebebeb', '#5dc24a', '#ff514e', '#79bfd9', '#8a8a8a'];

export const showSentimentByTopic = (sources, filter) => {
    const filtered = [];
    sources.forEach((source) => source.aspectBase === filter && filtered.push(source));

    const { positive, negative, neutral, unknown } = countData(filtered);
    const total = positive + negative + neutral + unknown;
    const datasets = prepareDatasets(sentiments, colors, positive, negative, neutral, unknown);
    const data = {
        type: 'horizontalBar',
        labels: [filter],
        datasets,
    };

    return <HorizontalBar data={data} options={getOptions(total)} />;
};
