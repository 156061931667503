import Balls from '../../components/Balls';
import LegendNegative from '../../components/legends/LegendNegative';
import LegendNeutral from '../../components/legends/LegendNeutral';
import LegendPositive from '../../components/legends/LegendPositive';
import PaginatedComponent from '../../components/PaginatedComponent';
import SentimentLegend from '../../components/SentimentLegend';

const filterTextsByOpinion = (filterContext, entry) => {
    filterContext.reset();
    filterContext.setOpinionUsingModifierAsFallback({ ...entry, phrase: entry.sentimentWord });
    filterContext.setAspect(entry.label.aspectBase);
};

const OpinionBalls = ({ data, aspect }) =>
    data ? (
        <>
            <PaginatedComponent
                data={data}
                render={(paginatedData) => (
                    <Balls
                        data={paginatedData}
                        centralNodeText={aspect}
                        onClick={filterTextsByOpinion}
                        getLabel={(dataEntry) => dataEntry.label.sentimentWord || dataEntry.label.modifierWord}
                    />
                )}
            />
            <SentimentLegend>
                <LegendPositive />
                <LegendNegative />
                <LegendNeutral />
            </SentimentLegend>
        </>
    ) : null;

export default OpinionBalls;
