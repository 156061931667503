import style from './style.module.scss';

const ModifierWord = ({ modifierWord, count, onClick, type }) => (
    <div className={style['OpinionTree__panel__modifier-word']} onClick={onClick}>
        <div className={style['OpinionTree__panel__modifier-word--wrapper']}>
            <div className={style['OpinionTree__panel__modifier-word--left']}>
                {type === 'negative' ? <i className="far fa-frown" /> : <i className="far fa-smile" />}
                <span className={style['OpinionTree__panel__modifier-word--text']}>{modifierWord}</span>
            </div>
            <span className={style['OpinionTree__panel__modifier-word--count']}>{count}</span>
        </div>
    </div>
);

const OpinionTreePanel = ({ type, data, onClick }) =>
    data.length > 0 && (
        <div className={style['OpinionTree__panel']}>
            <div className={style[`OpinionTree__panel--${type}`]}>
                {data.map((el) => (
                    <ModifierWord {...el} type={type} key={el.phrase} onClick={() => onClick(el)} />
                ))}
            </div>
        </div>
    );

export default OpinionTreePanel;
