import 'moment/locale/pl';

import * as moment from 'moment';
import React from 'react';
import ReactSelect from 'screens/Report/Main/components/ReactSelector';

import usePreparedDates from './hooks';
import style from './style.module.scss';

moment.locale('pl');

const MonthsAndYearSelector = ({ month, onMonthSelect, onYearSelect, minDate, maxDate }) => {
    const onChangeYear = ({ value }) => onYearSelect(month, value);
    const onChangeMonth = ({ value }) => onMonthSelect(month, value);

    const { yearToDisplay, monthToDisplay, yearsOptions, monthsOptions } = usePreparedDates(month, minDate, maxDate);

    return (
        <div className={style.monthAndYearSelector}>
            <ReactSelect
                value={monthToDisplay}
                onChange={onChangeMonth}
                options={monthsOptions}
                className={style.monthSelector}
            />

            <ReactSelect
                value={yearToDisplay}
                onChange={onChangeYear}
                options={yearsOptions}
                className={style.yearSelector}
            />
        </div>
    );
};

export default MonthsAndYearSelector;
