import React from 'react';
import { useTranslation } from 'react-i18next';

import style from './style.module.scss';

const LegendPositive = () => {
    const { t } = useTranslation();
    return <span className={style['Legend--positive']}>{t('positive')}</span>;
};

export default LegendPositive;
