import annotationPlugin from 'chartjs-plugin-annotation';
import React, { useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Chart, ChartBodyLine, ChartTitle } from 'screens/shared/style';
import { prepareTopicSentimentByDate } from 'utils/showTopicSentimentByDate';
import prepareOptions from 'utils/showTopicSentimentByDate/options';

import { ExportImgButtonForChart } from '../components/ExportImgButton';
import SentimentLegend from '../components/SentimentLegend';
import useAnnotation from './useAnnotation';

const TopicSentimentTime = ({ aspect }) => {
    const { t } = useTranslation();
    const componentRef = useRef();
    const posts = useSelector((state) => state.report.posts);
    const data = aspect && prepareTopicSentimentByDate(posts, { key: 'aspectBase', value: aspect });
    const { min, max } = useAnnotation(data ? data.labels : [], data ? data.rawDateKey : '');

    return (
        data && (
            <Chart>
                <div className="bg-white" ref={componentRef}>
                    <ChartTitle>
                        <div dangerouslySetInnerHTML={{ __html: t('topics.topicSentimentTime.title', { aspect }) }} />
                    </ChartTitle>

                    <ChartBodyLine>
                        <Line
                            data={data}
                            options={prepareOptions(data.dateKey, min, max)}
                            plugins={[annotationPlugin]}
                        />
                        <SentimentLegend />
                    </ChartBodyLine>
                </div>
                <ExportImgButtonForChart componentRef={componentRef} />
            </Chart>
        )
    );
};

export default TopicSentimentTime;
