import { countDataByLabel } from '../chartHelpers';

const NOT_FOUND = 'NOT_FOUND';

const MEDIA_CATEGORIES = {
    FACEBOOK: 'Facebook',
    TWITTER: 'Twitter',
    OTHER: 'Inne',
    FILE: 'Plik',
};

export const CATEGORIES = {
    SUMMARY: 'Suma',
    ...MEDIA_CATEGORIES,
};

const getCountByCategory = (data, category) =>
    data.reduce((total, value) => (value.category === category ? total + value.count : total), 0);

const prepareCategoryCounts = (sourcesTotalBySite) =>
    Object.keys(MEDIA_CATEGORIES).map((key) => ({
        category: MEDIA_CATEGORIES[key],
        count: getCountByCategory(sourcesTotalBySite, MEDIA_CATEGORIES[key]),
    }));

const prepareSourceBySourceGroup = (sourcesTotalBySite) => [
    {
        category: CATEGORIES.SUMMARY,
        count: sourcesTotalBySite.reduce((total, value) => total + value.count, 0),
    },
    ...prepareCategoryCounts(sourcesTotalBySite),
];

const getSourcesTotalBySite = (countedDataByLink, data) =>
    Object.keys(countedDataByLink).map((key) => {
        const post = data.find((el) => el.page === key);

        return {
            category: post.category,
            name: key,
            count: countedDataByLink[key],
        };
    });

const filterPostsNotFoundByLabel = (posts, labelToFilter = 'aspectBase') =>
    posts.filter((el) => el[labelToFilter] !== NOT_FOUND);

const prepareUniquePostsByFilename = (uniqueFilenames, posts) => {
    const uniquePostsByFilename = new Map();

    for (let filename of uniqueFilenames) {
        if (!uniquePostsByFilename.has(filename)) {
            const post = posts.find((el) => el.filename === filename);
            uniquePostsByFilename.set(filename, post);
        }
    }

    return uniquePostsByFilename;
};

const extractUniquePostsByFilename = (posts) => {
    const allPages = posts.map((el) => el.filename);
    const uniquePages = new Set(allPages);
    const uniqueFilenames = Array.from(uniquePages);
    const uniquePostsByFilename = prepareUniquePostsByFilename(uniqueFilenames, posts);

    return Array.from(uniquePostsByFilename, ([_, value]) => value);
};

export const prepareSourceTotal = (posts) => {
    const postsWithoutNotFound = filterPostsNotFoundByLabel(posts);
    const uniquePosts = extractUniquePostsByFilename(postsWithoutNotFound);
    const countedDataByLink = countDataByLabel(uniquePosts, () => 'page');
    const sourcesTotalBySite = getSourcesTotalBySite(countedDataByLink, uniquePosts);
    const sourcesTotal = prepareSourceBySourceGroup(sourcesTotalBySite);

    return {
        sourcesTotal,
        sourcesTotalBySite,
    };
};
