import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Chart, ChartTitle } from 'screens/shared/style';
import { showSentimentByTopic } from 'utils/showSentimentByTopic/showSentimentByTopic';

import { ExportImgButtonForChart } from '../components/ExportImgButton';
import SentimentLegend from '../components/SentimentLegend';

const TopicSentiment = ({ aspect }) => {
    const { t } = useTranslation();
    const componentRef = useRef();
    const posts = useSelector((state) => state.report.posts);
    const data = aspect ? showSentimentByTopic(posts, aspect) : null;

    return (
        <Chart>
            <div ref={componentRef} className="bg-white">
                <ChartTitle>
                    {t('topics.sentiment.title')} <strong>{aspect}</strong>
                </ChartTitle>
                <div>{data}</div>
                {aspect && <SentimentLegend unknownText={t('topics.sentiment.features')} />}
            </div>
            {aspect && <ExportImgButtonForChart componentRef={componentRef} />}
        </Chart>
    );
};

export default TopicSentiment;
