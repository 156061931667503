import * as R from 'ramda';

import { IGNORED } from '../model/ReportConstants';

const countGroupedByOpinionWord = (groupedCount) => (post) => {
    const opinionWord = post.sentimentWord === '' ? 'modifierWord' : 'sentimentWord';
    let index = R.findIndex(R.propEq(opinionWord, post[opinionWord]))(groupedCount);

    if (index === -1) {
        index =
            groupedCount.push({
                opinionWord: opinionWord,
                [opinionWord]: post[opinionWord],
                sentiments: {},
            }) - 1;
    }

    const item = groupedCount[index];
    item.sentiments[post.sentiment] = (item.sentiments[post.sentiment] || 0) + 1;
};

function flattenToSentiments(groupedCount) {
    return R.chain(
        (item) =>
            Object.keys(item.sentiments).map((key) => ({
                [item.opinionWord]: item[item.opinionWord],
                opinionWord: item.opinionWord,
                sentiment: key,
                count: item.sentiments[key],
            })),
        groupedCount,
    );
}

function calculateSize(item, max) {
    const maxSize = 80;
    const minSize = 60;
    return Math.ceil((Math.ceil((item.count / max) * 100) * maxSize) / 100) + minSize;
}

function calculateX(index) {
    const centerRadius = 110;
    return Math.sin(60 * index * (Math.PI / 180)) * centerRadius;
}

function calculateY(index) {
    const centerRadius = 110;
    return Math.cos(60 * index * (Math.PI / 180)) * centerRadius;
}

const calculateStyles = (x, y, size, totalOffset) => ({
    height: size,
    width: size,
    left: `${(x + totalOffset).toString()}px`,
    top: `${(y + totalOffset).toString()}px`,
});

function descendingByCount(a, b) {
    return b.count - a.count;
}

function byOpinion(opinion) {
    return (post) =>
        post.sentimentWord === opinion.sentimentWord &&
        post.sentiment === opinion.sentiment &&
        post.modifierWord === opinion.modifierWord;
}

function mapToBallChartData(flatCount, labelMapper) {
    const maxCount = Math.max(...flatCount.map((item) => item.count));
    const offsetToParentCenter = 90;
    let offsetToChildCenter = 0;
    let totalOffset = 90;
    let x = 0;
    let y = 0;

    return flatCount.map((item, index) => {
        const size = calculateSize(item, maxCount);
        offsetToChildCenter = size / 2;
        totalOffset = offsetToParentCenter - offsetToChildCenter;
        x = calculateX(index);
        y = calculateY(index);
        const styles = calculateStyles(x, y, size, totalOffset);

        return { ...item, styles, label: labelMapper(item) };
    });
}

export const prepareOpinionsForTopic = (posts, aspectBase, filters = []) => {
    const preFilteredBySentiment =
        filters.length !== 0 ? posts.filter((post) => filters.includes(post.sentiment)) : posts;
    const groupedByOpinionWord = [];
    preFilteredBySentiment
        .filter((post) => post.aspectBase === aspectBase)
        .forEach(countGroupedByOpinionWord(groupedByOpinionWord));
    const flatCount = flattenToSentiments(groupedByOpinionWord).sort(descendingByCount);

    return mapToBallChartData(flatCount, (item) => ({
        sentimentWord: item.sentimentWord,
        modifierWord: item.modifierWord,
        opinionWord: item.opinionWord,
        aspectBase,
    }));
};

export const prepareTopicsForOpinion = (posts, opinion) => {
    const countObject = posts
        .filter(byOpinion(opinion))
        .filter((post) => post.aspectBase !== IGNORED)
        .reduce((accumulator, currentValue) => {
            accumulator[currentValue.aspectBase] = (accumulator[currentValue.aspectBase] || 0) + 1;
            return accumulator;
        }, {});

    const flatCount = Object.keys(countObject)
        .map((key) => ({
            sentimentWord: opinion.sentimentWord,
            sentiment: opinion.sentiment,
            count: countObject[key],
            aspectBase: key,
        }))
        .sort(descendingByCount);

    return mapToBallChartData(flatCount, (item) => item.aspectBase);
};
