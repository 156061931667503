import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from 'state/auth/actions';

import style from './style.module.scss';
import { initialValues, validationSchemaWithTranslates } from './validationSchema';

const Login = ({ history }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isFetching = useSelector((state) => state.auth.isFetching);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const apiErrorMessage = useSelector((state) => state.auth.errorMessage);

    useEffect(() => {
        if (isAuthenticated) {
            history.push('/browser');
        }
    });

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchemaWithTranslates(t)}
            onSubmit={(values) => dispatch(loginUser(values))}
        >
            {({ values, errors, touched, handleChange, handleBlur }) => (
                <div className={style['Login__form-container']}>
                    <Form>
                        <Paper elevation={3} className={style['Login__form-elements-container']}>
                            {apiErrorMessage && apiErrorMessage}

                            <TextField
                                type="text"
                                name="username"
                                label={t('login.login')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.username}
                            />
                            {errors.username && touched.username && errors.username}

                            <TextField
                                type="password"
                                name="password"
                                label={t('login.password')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                            />
                            {errors.password && touched.password && errors.password}

                            <Button type="submit" disabled={isFetching} color="primary">
                                {t('login.action')}
                            </Button>
                        </Paper>
                    </Form>
                </div>
            )}
        </Formik>
    );
};

export default Login;
