import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { logoutUser } from 'state/auth/actions';

import style from './style.module.scss';

const MenuLogout = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    return (
        <div className={`${style.link} `} onClick={() => dispatch(logoutUser())} title={t('menu.logout')}>
            <i className="fas fa-sign-out-alt" />
        </div>
    );
};

export default MenuLogout;
