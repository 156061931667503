import { countDataByLabel } from '../chartHelpers';
import Colors from '../Colors';

export const countSources = (mappedData) =>
    mappedData.map((element) => {
        const countedData = countDataByLabel(element.data);
        const sourcesTotalBySite = Object.keys(countedData).map((key) => ({
            category: key,
            name: key,
            count: countedData[key],
        }));

        return {
            date: element.date,
            data: sourcesTotalBySite,
        };
    });

export const mapToChartData = (sourcePosts, countedSources) => {
    const fullNames = sourcePosts.map((el) => (isNaN(el.page) ? el.page : 'Plik'));
    const fullDates = countedSources.map((el) => el.date);
    const uniqueNames = Array.from(new Set(fullNames));

    return uniqueNames.map((name) => {
        const data = fullDates.map((date) => {
            const year = countedSources.find((el) => el.date === date);
            const data = year.data.find((el) => el.name === name);
            return data ? data.count : 0;
        });

        return {
            maxBarThickness: 40,
            label: name,
            data,
            backgroundColor: Colors.randomAny(),
        };
    });
};

export const prepareDataToDisplayForChart = (countedSources, sourcePosts) => ({
    labels: countedSources.map((el) => el.date),
    datasets: mapToChartData(sourcePosts, countedSources),
    type: 'bar',
});
