import { findOpinionsSortedByCount_fullEqual } from 'utils/postsTransformers';

export const extractAllOpinion = (posts) => {
    const allData = findOpinionsSortedByCount_fullEqual(posts);
    allData.forEach((el) => (el.count = el.rawCountBeforeProcess));
    return allData;
};

export const extractAllOpinion_sentimentWord = (posts) => {
    const allData = findOpinionsSortedByCount_fullEqual(posts, 'sentimentWord');
    allData.forEach((el) => (el.count = el.rawCountBeforeProcess));
    return allData;
};

export const prepareListsData = (posts) => {
    const allData = extractAllOpinion_sentimentWord(posts);
    const negative = [];
    const positive = [];
    const neutral = [];
    const unknown = [];

    allData.forEach((data) => {
        switch (data.sentiment) {
            case 'negatywny':
                negative.push(data);
                break;
            case 'pozytywny':
                positive.push(data);
                break;
            case 'neutralny':
                neutral.push(data);
                break;
            case 'nieznany':
                unknown.push(data);
                break;
            default:
                break;
        }
    });

    negative.sort((a, b) => a.count - b.count).reverse();
    positive.sort((a, b) => a.count - b.count).reverse();
    neutral.sort((a, b) => a.count - b.count).reverse();
    unknown.sort((a, b) => a.count - b.count).reverse();

    return { negative, positive, neutral, unknown };
};
