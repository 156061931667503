import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Container from './Container';
import StandaloneProjectList from './StandaloneProjectList';
import style from './style.module.scss';

const Browser = () => {
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = useState('');
    const onError = (e) => setErrorMessage(e);

    return (
        <Container errorMessage={errorMessage} isSelector>
            <p className={style['Browser__report-title']}>{t('browserScreen.title')}</p>
            <StandaloneProjectList onError={onError} />
        </Container>
    );
};

export default Browser;
