import logo from 'assets/img/logo-white.png';
import React from 'react';

import MenuBack from './Buttons/Back';
import MenuExport from './Buttons/Export';
import MenuLogout from './Buttons/Logout';
import LanguageSelector from './LanguageSelector';
import MenuList from './MenuList';
import style from './style.module.scss';

const Navbar = () => (
    <div className={style['navbar']}>
        <img src={logo} className={style['navbar__image']} alt="Aspekty Technologie językowe" />

        <div className={style.buttons}>
            <MenuBack />
            <MenuExport />
            <MenuLogout />
        </div>

        <MenuList />

        <div className={style.languages}>
            <LanguageSelector />
        </div>
    </div>
);

export default Navbar;
