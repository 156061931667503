import ChartJS from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { useCallback, useContext, useMemo } from 'react';
import { FilterContext } from 'screens/Report/utils/FilterContext';
import { DATE_SOURCE } from 'utils/showTopicSentimentByDate';

ChartJS.pluginService.register(annotationPlugin);

const MONTHS_IN_YEAR_NUMBER = 12;

const useAnnotation = (labels, dateKey) => {
    const { state } = useContext(FilterContext);

    const prepareValue = useCallback(
        (date) => {
            if (!date) {
                return 0;
            }

            if (dateKey === DATE_SOURCE.YEAR) {
                const yearValue = labels.indexOf(date.year() + '');
                const monthValue = (date.month() + 1) / MONTHS_IN_YEAR_NUMBER;
                return yearValue + monthValue;
            } else if (dateKey === DATE_SOURCE.MONTH) {
                return labels.indexOf(date.month() + '');
            } else if (dateKey === DATE_SOURCE.DAY) {
                return labels.indexOf(date.date() + '');
            }
        },
        [labels, dateKey],
    );

    const min = useMemo(() => prepareValue(state.startDate), [state.startDate, prepareValue]);

    const max = useMemo(() => prepareValue(state.endDate), [state.endDate, prepareValue]);

    return { min, max };
};

export default useAnnotation;
