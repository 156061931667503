import 'react-dates/initialize';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Spinner from 'components/Spinner';
import React from 'react';
import { Suspense } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Browser from 'screens/Browser';
import Report from 'screens/Report';

import AuthenticatedComponent from '../auth/AuthenticatedComponent';
import Login from './Login';

const appTheme = createMuiTheme({
    palette: {
        primary: { main: '#5dc24a' },
        secondary: { main: '#79bfd9' },
    },
});

const App = () => {
    const BrowserRoute = AuthenticatedComponent(Browser);
    const ReportRoute = AuthenticatedComponent(Report);

    return (
        <BrowserRouter>
            <Suspense fallback={Spinner}>
                <ThemeProvider theme={appTheme}>
                    <Switch>
                        <Route exact path="/login" render={(ownProps) => <Login {...ownProps} />} />
                        <Route path="/browser" render={(ownProps) => <BrowserRoute {...ownProps} />} />
                        <Route path="/report" render={(ownProps) => <ReportRoute {...ownProps} />} />
                        <Redirect from="/" to="/login" />
                    </Switch>
                </ThemeProvider>
            </Suspense>
        </BrowserRouter>
    );
};

export default App;
