export const getOptions = (summary) => ({
    maintainAspectRatio: false,
    responsive: true,
    maxBarThickness: 10,
    legend: {
        display: false,
        position: 'bottom',
        labels: {
            fontSize: 10,
            fontFamily: 'roboto',
        },
    },
    scales: {
        xAxes: [
            {
                stacked: true,
                gridLines: {
                    display: false,
                },
                ticks: {
                    fontFamily: 'roboto',
                    fontSize: 10,
                    min: 0, // it is for ignoring negative step.
                    beginAtZero: true,
                    callback: (value) => {
                        if (Math.floor(value) === value) {
                            return value;
                        }
                    },
                },
            },
        ],
        yAxes: [
            {
                stacked: true,
                maxBarThickness: 10,
                gridLines: {
                    display: false,
                },
                ticks: {
                    fontFamily: 'roboto',
                    fontSize: 10,
                },
            },
        ],
    },
    tooltips: {
        backgroundColor: 'rgb(255,255,255)',
        borderColor: 'rgb(64,64,64)',
        borderWidth: 1,
        titleFontColor: 'rgb(64,64,64)',
        titleFontSize: 0,
        titleFontFamily: 'roboto',
        bodyFontColor: 'rgb(64,64,64)',
        bodyFontFamily: 'roboto',
        bodyFontSize: 10,
        footerFontColor: 'rgb(64,64,64)',
        footerFontFamily: 'roboto',
        footerFontSize: 10,
        callbacks: {
            afterBody: (t, d) => {
                return `łącznie: ${summary}`;
            },
        },
    },
});
