import Balls from '../../components/Balls';
import PaginatedComponent from '../../components/PaginatedComponent';

const filterTextsByFeature = (filterContext, entry) => {
    filterContext.reset();
    filterContext.setAspect(entry.label.aspectBase);

    if (entry.sentimentWord) {
        filterContext.setFeature({
            sentimentWord: entry.sentimentWord,
        });
    } else {
        filterContext.setFeature({
            modifierWord: entry.modifierWord,
        });
    }
};

const FeatureBalls = ({ data, aspect }) =>
    data ? (
        <PaginatedComponent
            data={data}
            render={(paginatedData) => (
                <Balls
                    data={paginatedData}
                    centralNodeText={aspect}
                    onClick={filterTextsByFeature}
                    getLabel={(dataEntry) => dataEntry.label.sentimentWord || dataEntry.label.modifierWord}
                />
            )}
        />
    ) : null;

export default FeatureBalls;
