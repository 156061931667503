import './style.scss';

import React, { useEffect, useState } from 'react';

const FIRST_PAGE = 1;
const DEFAULT_MARGIN = 1;
const DEFAULT_VISIBLE = 1;

const Element = ({ number, currentPage, onClick }) => (
    <li className={`${number === currentPage ? 'active' : ''} link`} onClick={() => onClick(number)}>
        {number}
    </li>
);

const Pagination = ({ totalPages, currentPage, onPageChange, className = '' }) => {
    const [pagesToDisplay, setPagesToDisplay] = useState([]);

    useEffect(() => {
        const allPages = [...Array(totalPages).keys()].map((el) => el + 1);

        const modifyPagesToDisplay = (start, end) => {
            const pagesToDisplay = [...allPages].slice(start, end);
            setPagesToDisplay(pagesToDisplay);
        };

        const preparePagesToDisplay = () => {
            const pagesNumber = DEFAULT_MARGIN * 2 + DEFAULT_VISIBLE;
            const length = allPages.length;
            const currentPageIndex = allPages.indexOf(currentPage);
            const elementsAfterCurrentWithCurrent = [...allPages].slice(currentPageIndex, length + 1);
            const elementsBeforeCurrent = [...allPages].slice(0, currentPageIndex);

            if (elementsBeforeCurrent.length < DEFAULT_MARGIN) {
                modifyPagesToDisplay(0, pagesNumber);
            } else if (elementsAfterCurrentWithCurrent.length <= DEFAULT_MARGIN + DEFAULT_VISIBLE) {
                const end = length + 1;
                const start = length - pagesNumber;
                modifyPagesToDisplay(start, end);
            } else {
                const start = currentPageIndex - DEFAULT_MARGIN;
                const end = currentPageIndex + DEFAULT_MARGIN + 1;
                modifyPagesToDisplay(start, end);
            }
        };

        preparePagesToDisplay();
    }, [currentPage, totalPages]);

    const onClickPrevious = () => {
        const prevPage = currentPage - 1;
        if (prevPage >= FIRST_PAGE) {
            onPageChange(prevPage);
        }
    };

    const onClickNext = () => {
        const nextPage = currentPage + 1;
        if (nextPage <= totalPages) {
            onPageChange(nextPage);
        }
    };

    const onClickFirstPage = () => onPageChange(FIRST_PAGE);

    const onClickLastPage = () => onPageChange(totalPages);

    return (
        <div className={className}>
            <ul className="pagination">
                <li className={`previous ${currentPage === FIRST_PAGE ? 'disabled' : ''}`} onClick={onClickFirstPage}>
                    <i className="fa fa-step-backward" />
                </li>

                <li className={`previous ${currentPage === FIRST_PAGE ? 'disabled' : ''}`} onClick={onClickPrevious}>
                    <i className="fa fa-angle-double-left" />
                </li>

                {pagesToDisplay.map((el) => (
                    <Element currentPage={currentPage} key={el} number={el} onClick={onPageChange} />
                ))}

                <li className={`next ${currentPage >= totalPages ? 'disabled' : ''}`} onClick={onClickNext}>
                    <i className="fa fa-angle-double-right" />
                </li>

                <li className={`next ${currentPage >= totalPages ? 'disabled' : ''}`} onClick={onClickLastPage}>
                    <i className="fa fa-step-forward" />
                </li>
            </ul>
        </div>
    );
};

export default Pagination;
