import React, { useContext } from 'react';

import { FilterContext } from '../../../utils/FilterContext';
import style from './style.module.scss';

const ENG_TO_PL_SENTIMENT = new Map([
    ['pozytywny', 'positive'],
    ['negatywny', 'negative'],
    ['neutralny', 'neutral'],
]);

const Ball = ({ styles, label, sentiment, count, onClick, isBlank }) => {
    const className = isBlank ? 'blankNode' : `${sentiment}Node`;

    return (
        <div className={`connectedNode ${className} nodeTooltip`} style={styles} onClick={onClick}>
            <span className={style['Balls__connected-text']}>{label}</span>
            <p className="tooltiptext">{`${sentiment}: ${count}`}</p>
        </div>
    );
};

const CentralBall = ({ text, children, sentiment }) => {
    const type = ENG_TO_PL_SENTIMENT.get(sentiment) || 'blank';
    const basicClassName = style['Balls__central-node'];
    const additionalClassName = style[`Balls__central-node--${type}`];

    return (
        <div className={`${basicClassName} ${additionalClassName}`}>
            <p className={style['Balls__central-text']}>{text}</p>
            {children}
        </div>
    );
};

const renderBallsAroundCenter = (data, onClick, filterContext, useBlankBallsAroundCenter, getLabel) =>
    data.map((dataEntry, index) => (
        <Ball
            key={`node${index}-${dataEntry.sentiment}-${dataEntry.count}`}
            styles={dataEntry.styles}
            label={getLabel(dataEntry)}
            sentiment={dataEntry.sentiment}
            count={dataEntry.count}
            isBlank={useBlankBallsAroundCenter}
            onClick={() => onClick(filterContext, dataEntry)}
        />
    ));

const Balls = ({ data, getLabel, centralNodeText, centralNodeSentiment, onClick, useBlankBallsAroundCenter }) => {
    const filterContext = useContext(FilterContext);

    return (
        <div className={style['Balls__container']}>
            <CentralBall text={centralNodeText} sentiment={centralNodeSentiment}>
                {renderBallsAroundCenter(data, onClick, filterContext, useBlankBallsAroundCenter, getLabel)}
            </CentralBall>
        </div>
    );
};

export default Balls;
