import { MAX_COUNT, MIN_DISPLAY_COUNT } from 'utils/postsTransformers';
import { sortByCount_MaxToMin } from 'utils/sorting';

const extractDataToDisplay = (data) => {
    data.sort(sortByCount_MaxToMin);
    const length = data.length;

    if (length < MAX_COUNT) {
        return data;
    } else {
        const maxHalfCount = MAX_COUNT / 2;
        const middleIndex = Math.floor(length / 2);
        const maxCounts = [...data].slice(0, maxHalfCount);
        const minCounts = [...data].slice(middleIndex, middleIndex + maxHalfCount);
        return [...maxCounts, ...minCounts];
    }
};

export const prepareDataForCloud = (posts, callback) => {
    let dataRaw = callback(posts);

    for (let minDisplayCount = MIN_DISPLAY_COUNT; minDisplayCount > -1; minDisplayCount--) {
        if (dataRaw.length < MAX_COUNT) {
            dataRaw = callback(posts, MIN_DISPLAY_COUNT - 1);
        } else {
            break;
        }
    }

    return extractDataToDisplay(dataRaw);
};
