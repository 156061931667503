import { useTranslation } from 'react-i18next';

import LegendNegative from '../legends/LegendNegative';
import LegendNeutral from '../legends/LegendNeutral';
import LegendPositive from '../legends/LegendPositive';
import LegendUnknown from '../legends/LegendUnknown';
import style from './style.module.scss';

const SentimentLegend = ({ children, unknownText }) => {
    const { t } = useTranslation();

    if (children) {
        return (
            <div className={style['Sentiment__legend__chart']}>
                <span className={style['Sentiment__lengend__title']}> {t('sentiment')}</span>
                {children}
            </div>
        );
    }

    return (
        <div className={style['Sentiment__legend__chart']}>
            <span className={style['Sentiment__lengend__title']}>{t('sentiment')}</span>
            <LegendPositive />
            <LegendNegative />
            <LegendNeutral />
            <LegendUnknown text={unknownText} />
        </div>
    );
};

export default SentimentLegend;
