const prepareOptions = (xAxesLabel, xMin, xMax) => ({
    maintainAspectRatio: false,
    legend: {
        display: false,
        position: 'bottom',
        labels: {
            fontSize: 10,
            fontFamily: 'roboto',
        },
    },
    scales: {
        xAxes: [
            {
                stacked: false,
                gridLines: {
                    display: true,
                },
                scaleLabel: {
                    display: true,
                    labelString: xAxesLabel || '-',
                },
                ticks: {
                    fontFamily: 'roboto',
                    fontSize: 10,
                },
            },
        ],
        yAxes: [
            {
                stacked: false,
                gridLines: {
                    display: true,
                },
                scaleLabel: {
                    display: true,
                    labelString: 'liczba postów',
                },
                ticks: {
                    fontFamily: 'roboto',
                    fontSize: 10,
                },
            },
        ],
    },
    tooltips: {
        backgroundColor: 'rgb(255,255,255)',
        borderColor: 'rgb(64,64,64)',
        borderWidth: 1,
        titleFontColor: 'rgb(64,64,64)',
        titleFontSize: 10,
        titleFontFamily: 'roboto',
        bodyFontColor: 'rgb(64,64,64)',
        bodyFontFamily: 'roboto',
        bodyFontSize: 10,
        footerFontColor: 'rgb(64,64,64)',
        footerFontFamily: 'roboto',
        footerFontSize: 10,
    },
    annotation: {
        annotations: [
            {
                type: 'box',
                drawTime: 'beforeDatasetsDraw',
                id: 'a-box-1',
                xScaleID: 'x-axis-0',
                yScaleID: 'y-axis-0',
                xMin,
                xMax,
                backgroundColor: 'rgba(86,172,70, 0.1)',
            },
        ],
    },
});

export default prepareOptions;
