import Spinner from 'components/Spinner';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { cleanModifierWordCache } from 'screens/Report/Main/OpinionTree/helpers';

import { getProjects } from '../../state/project/actions';
import { getReport, parametrizeReport } from '../../state/report/actions';
import ProjectsList from './ProjectsList';

const COOKIE_KEY = 'projectParameters';
const MAX_AGE = 604800;

const StandaloneProjectsList = ({ onError, cookies }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const projects = useSelector((state) => state.project.projects);
    const isGettingProjects = useSelector((state) => state.project.isGettingProjects);

    const [isLoadingBundle, setIsLoadingBundle] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);

    useEffect(() => {
        const projectParameters = cookies.get(COOKIE_KEY);
        dispatch(getProjects());

        if (projectParameters && projects) {
            const selectedProject = projects.find((el) => el.parameters.project === projectParameters.project);
            selectedProject && setSelectedProject(selectedProject);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleProjectClick = async (selectedProject) => {
        cleanModifierWordCache();
        await loadProject(selectedProject.parameters);
        setSelectedProject(selectedProject);
    };

    const setCookie = (cookie) => cookies.set(COOKIE_KEY, cookie, { path: '/', maxAge: MAX_AGE });

    const loadProject = async (parameters) => {
        setIsLoadingBundle(true);

        try {
            await dispatch(parametrizeReport(parameters, setCookie));
            await dispatch(getReport(parameters.project, parameters.reportId));
            history.push('/report/sources');
        } catch (error) {
            onError(error.message || t('genericError'));
        } finally {
            setIsLoadingBundle(false);
        }
    };

    return !projects.length || isGettingProjects || isLoadingBundle ? (
        <Spinner />
    ) : (
        <ProjectsList selectedProject={selectedProject} projects={projects} handleClick={handleProjectClick} />
    );
};

StandaloneProjectsList.propTypes = {
    cookies: PropTypes.object.isRequired,
    onError: PropTypes.func.isRequired,
};

export default withCookies(StandaloneProjectsList);
