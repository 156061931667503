import moment from 'moment';
import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FilterContext } from 'screens/Report/utils/FilterContext';

const comparePostDate = (startDate, endDate) => ({ date }) => {
    const postDate = moment(date);

    if (startDate && !endDate) {
        return postDate.isSameOrAfter(startDate, 'day');
    }

    if (endDate && !startDate) {
        return postDate.isSameOrBefore(endDate, 'day');
    }

    if (endDate && startDate) {
        return postDate.isSameOrAfter(startDate, 'day') && postDate.isSameOrBefore(endDate, 'day');
    }
};

export const usePostsByDate = () => {
    const posts = useSelector((state) => state.report.posts);
    const filterContext = useContext(FilterContext);

    const displayPosts = useMemo(() => {
        const { startDate, endDate } = filterContext.state;
        return startDate || endDate ? posts.filter(comparePostDate(startDate, endDate)) : [...posts];
    }, [posts, filterContext.state]);

    return displayPosts;
};

export const useKeepSelectedDates = () => {
    const filterContext = useContext(FilterContext);

    const keepSelectedDates = () => {
        const { startDate, endDate } = filterContext.state;
        filterContext.reset();
        filterContext.setStartDate(startDate);
        filterContext.setEndDate(endDate);
    };

    return keepSelectedDates;
};
