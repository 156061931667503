import { GET_PROJECTS_FAILURE, GET_PROJECTS_REQUEST, GET_PROJECTS_SUCCESS } from './actionsTypes';

export default function reducer(
    state = {
        isGettingProjects: false,
        projects: [],
        authenticated: false,
    },
    action,
) {
    switch (action.type) {
        case GET_PROJECTS_REQUEST:
            return Object.assign({}, state, {
                isGettingProjects: true,
            });
        case GET_PROJECTS_SUCCESS:
            return Object.assign({}, state, {
                isGettingProjects: false,
                projects: action.response,
                authenticated: action.authenticated || false,
            });
        case GET_PROJECTS_FAILURE:
            return Object.assign({}, state, {
                isGettingProjects: false,
            });
        default:
            return state;
    }
}
