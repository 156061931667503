import React from 'react';
import { ChartIconArea, ChartTooltip } from 'screens/shared/style';

const CommentTooltip = ({ children }) => (
    <ChartIconArea>
        <div className="cloud-icon">
            <i className="far fa-comments" />
        </div>
        <ChartTooltip>{children}</ChartTooltip>
    </ChartIconArea>
);

export default CommentTooltip;
