import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import { withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';
import StandaloneProjectList from 'screens/Browser/StandaloneProjectList';

import Opinions from './Opinions';
import OpinionTree from './OpinionTree';
import Sentiments from './Sentiments';
import Sources from './Sources';
import style from './style.module.scss';
import Aspects from './Topics';

class Main extends Component {
    componentDidMount() {
        this.handleReportContentHeightChange();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location !== prevProps.location) {
            this.stopLoadingStripe();
        }
    }

    setMainDivRef = (divElement) => (this.divElement = divElement);

    handleReportContentHeightChange = () => {
        if (this.divElement) {
            const height = this.divElement.clientHeight;
            this.props.onContentHeightDetermined(height);
        }
    };

    startLoadingStripe = () => {
        return this.LoadingBar.continuousStart();
    };

    stopLoadingStripe = () => {
        return this.LoadingBar.complete();
    };

    render() {
        const { packageName, refreshedDate } = this.props;

        return (
            <div ref={this.setMainDivRef} className={style.main}>
                <LoadingBar height={3} color="rgb(86, 172, 70)" onRef={(ref) => (this.LoadingBar = ref)} />

                <div className={style['selector']}>
                    <StandaloneProjectList onError={() => {}} />
                </div>

                <div className={style['Report__content']}>
                    <Switch>
                        <Route exact path="/report/sources" onEnter={this.startLoadingStripe} component={Sources} />
                        <Route
                            exact
                            path="/report/sentiments"
                            onEnter={this.startLoadingStripe}
                            component={Sentiments}
                        />
                        <Route exact path="/report/aspectBases" onEnter={this.startLoadingStripe} component={Aspects} />
                        <Route exact path="/report/opinions" onEnter={this.startLoadingStripe} component={Opinions} />
                        <Route exact path="/report/tree" onEnter={this.startLoadingStripe} component={OpinionTree} />
                        <Route render={() => <p>Niewłaściwy adres</p>} />
                    </Switch>
                    <ReactResizeDetector handleHeight onResize={this.handleReportContentHeightChange} />
                </div>

                <div className={style['Report__title']}>
                    {packageName}, odświeżono {refreshedDate}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        packageName: state.report.parameters.project,
        refreshedDate: state.report.parameters.refreshed,
    };
};

export default connect(mapStateToProps, null)(withRouter(withRouter(Main)));
