import React from 'react';
import { useTranslation } from 'react-i18next';

import style from './style.module.scss';

const LegendUnknown = ({ text }) => {
    const { t } = useTranslation();
    return <span className={style['Legend--unknown']}>{text || t('unknown')}</span>;
};

export default LegendUnknown;
