import { useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Chart, ChartBody, ChartTitle } from 'screens/shared/style';
import {
    showSourcesByMonthAndYearAndSource,
    showSourcesByYearAndSource,
} from 'utils/showPostsBySource/showSourcesByDate';
import { prepareSourceTotal } from 'utils/showPostsBySource/showSourcesBySource';

import { ExportImgButtonForChart } from '../components/ExportImgButton';
import options from './options';
import SourcesBySrc from './SourcesBySrc';

const Source = () => {
    const { t } = useTranslation();
    const posts = useSelector((state) => state.report.posts);

    const chartSourcesByMonth = showSourcesByMonthAndYearAndSource(posts);
    const chartSourcesByYear = showSourcesByYearAndSource(posts);
    const sourceBySite = prepareSourceTotal(posts);

    const chartSourcesByYearRef = useRef();
    const chartSourcesByMonthRef = useRef();

    return (
        <>
            <Chart>
                <ChartTitle>{t('textsAndSources.textsSources.title')}</ChartTitle>
                <SourcesBySrc
                    sourcesTotal={sourceBySite.sourcesTotal}
                    sourcesTotalBySite={sourceBySite.sourcesTotalBySite}
                />
            </Chart>

            <Chart>
                <div ref={chartSourcesByYearRef} className="bg-white">
                    <ChartTitle>{t('textsAndSources.textsSourcesByYearsTitle')}</ChartTitle>
                    <ChartBody>
                        <Bar data={chartSourcesByYear} options={options} redraw />
                    </ChartBody>
                </div>
                <ExportImgButtonForChart componentRef={chartSourcesByYearRef} />
            </Chart>

            <Chart>
                <div ref={chartSourcesByMonthRef} className="bg-white">
                    <ChartTitle>{t('textsAndSources.textsSourcesByMonthsTitle')}</ChartTitle>
                    <ChartBody>
                        <Bar data={chartSourcesByMonth} options={options} redraw />
                    </ChartBody>
                </div>
                <ExportImgButtonForChart componentRef={chartSourcesByMonthRef} />
            </Chart>
        </>
    );
};

export default Source;
