import '../../public/styles/style.scss';

import Spinner from 'components/Spinner';
import * as R from 'ramda';
import { Component } from 'react';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import Main from 'screens/Report/Main';
import Navbar from 'screens/Report/Navbar';
import PostsList from 'screens/Report/PostsList';
import { FilterProvider } from 'screens/Report/utils/FilterContext';

import { getReport, parametrizeReport } from '../../state/report/actions';
import { cleanModifierWordCache } from './Main/OpinionTree/helpers';
import style from './style.module.scss';
import { Modal } from './utils/Modal';
import { ModalProvider } from './utils/ModalContext';

const ReportSpinner = () => (
    <div className={style['loading-container']}>
        <Spinner />
    </div>
);

class Report extends Component {
    constructor(props) {
        super(props);
        const { cookies } = props;
        this.state = {
            mainContentHeight: 0,
            loading: false,
            projectParametersFromCookies: cookies.get('projectParameters') || null,
            errorMessage: '',
        };
    }

    componentDidMount() {
        if (R.isEmpty(this.props.parameters)) {
            cleanModifierWordCache();
            this.setState({ loading: true });
            this.loadProject(this.state.projectParametersFromCookies);
        }
    }

    loadProject(parameters) {
        this.props
            .parametrizeReport(parameters, this.setCookie)
            .then(() => this.props.getReport(parameters))
            .then(() => this.setState({ loading: false }))
            .catch((error) =>
                this.setState({
                    error: true,
                    errorMessage: error.message,
                }),
            );
    }

    setCookie = (cookie) => {
        this.props.cookies.set('projectParameters', cookie, { path: '/', maxAge: 604800 });
    };

    handleMainContentHeightDetermined = (height) => {
        this.setState({ mainContentHeight: height });
    };

    render() {
        return (
            <FilterProvider>
                <ModalProvider>
                    {this.state.loading ? (
                        <ReportSpinner />
                    ) : (
                        <div className={style['main-container']}>
                            <Navbar />
                            <div className={style['flex-container']}>
                                <Main onContentHeightDetermined={this.handleMainContentHeightDetermined} />
                                <PostsList height={this.state.mainContentHeight} />
                            </div>
                            <Modal />
                        </div>
                    )}
                </ModalProvider>
            </FilterProvider>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    getReport: (parameters) => dispatch(getReport(parameters.project, parameters.reportId)),
    parametrizeReport: (parameters, setCookie) => dispatch(parametrizeReport(parameters, setCookie)),
});

const mapStateToProps = (state) => ({
    parameters: state.report.parameters,
});

export default connect(mapStateToProps, mapDispatchToProps)(withCookies(Report));
