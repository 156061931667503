import 'moment/locale/pl';

import * as moment from 'moment';
import { useMemo } from 'react';

moment.locale('pl');

const getYearsBetweenDates = (minDate, maxDate) => {
    const difference = maxDate.diff(minDate, 'years');
    const dates = [];

    for (let i = 0; i <= difference; i++) {
        dates.push(minDate.clone().add(i, 'years'));
    }

    const years = dates.map((date) => date.year());
    return years;
};

const usePreparedDates = (month, minDate, maxDate) => {
    const dates = useMemo(() => {
        const years = getYearsBetweenDates(minDate, maxDate);
        const yearsOptions = years.map((year) => ({ label: year, value: year }));
        const yearToDisplay = { label: month.year(), value: month.year() };

        const monthsOptions = moment.months().map((label, index) => ({ value: index, label }));

        const currentMonthNumber = month.month();
        const monthToDisplay = monthsOptions.find((el) => el.value === currentMonthNumber);

        return { yearToDisplay, monthToDisplay, yearsOptions, monthsOptions };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return dates;
};

export default usePreparedDates;
