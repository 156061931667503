import React from 'react';
import { useTranslation } from 'react-i18next';

import style from './style.module.scss';

const LegendNeutral = () => {
    const { t } = useTranslation();
    return <span className={style['Legend--neutral']}>{t('neutral')}</span>;
};

export default LegendNeutral;
