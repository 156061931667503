import React from 'react';
import { Dot } from 'react-animated-dots';
import { useTranslation } from 'react-i18next';

const Spinner = () => {
    const { t } = useTranslation();

    return (
        <p>
            {t('spinner')}
            <Dot>.</Dot>
            <Dot>.</Dot>
            <Dot>.</Dot>
        </p>
    );
};

export default Spinner;
