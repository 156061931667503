import * as PropTypes from 'prop-types';
import React from 'react';

import style from './style.module.scss';

const ListItem = ({ data, onClick }) => {
    const displayedValue = data.value || data.modifierWord || '<pusty />';

    return (
        <li onClick={() => onClick(data)} className={style['SelectList__heading']}>
            {displayedValue}
            <span className={style['SelectList__opinion-right--count']}>{data.count}</span>
        </li>
    );
};

ListItem.propTypes = {
    data: PropTypes.object,
    onClick: PropTypes.func,
};

export default ListItem;
