import { API_URL } from './constants';

const LOGIN_URL = `${API_URL}/login`;

export async function login({ username, password }) {
    const body = JSON.stringify({ username, password });
    return fetch(LOGIN_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: body,
    });
}
