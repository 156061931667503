import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import ListFilter from './ListFilter';
import ListItem from './ListItem';
import style from './style.module.scss';
import TypeBasedTitle from './TypeBasedTitle';

const prepareRows = (data, onClick) =>
    data.map((p, index) => <ListItem key={index} data={p} onClick={(opinion) => onClick(opinion)} />);

const CollapseList = ({ type, data, header, onClick, openId, setOpenId, showHeading = true }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const onFilter = (filter) => setFilteredData([...data].filter((item) => item.value.includes(filter)));
    const selectOpen = isOpen && openId === type;
    const icon = selectOpen ? 'fa-caret-up' : 'fa-caret-down';

    const onClickRow = (element) => {
        setFilteredData([...data]);
        onClick(element);
    };

    const onClickHeader = () => {
        setIsOpen(!isOpen);
        setOpenId(type);
    };

    useEffect(() => {
        setFilteredData([...data]);
        /* eslint-disable */
    }, [JSON.stringify(data)]);
    /* eslint-enable  */

    return (
        <div className={style['SelectList']}>
            <TypeBasedTitle type={type} onClick={onClickHeader}>
                <>
                    {header}
                    <span className={`fa ${icon} ${type} ${style['SelectList__opinion-toggle']}`} />
                </>
            </TypeBasedTitle>
            {selectOpen ? (
                <div className={style['SelectList__container']}>
                    <ListFilter onFilter={onFilter} />
                    {showHeading && (
                        <div className={style['SelectList__heading']}>
                            <span>Fraza</span>
                            <span className={style['SelectList__opinion-right']}>Liczba</span>
                        </div>
                    )}
                    <div className="pl-0">{prepareRows(filteredData, onClickRow)}</div>
                </div>
            ) : null}
        </div>
    );
};

CollapseList.propTypes = {
    data: PropTypes.array,
    type: PropTypes.string,
    header: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    openId: PropTypes.string.isRequired,
    setOpenId: PropTypes.func.isRequired,
    showHeading: PropTypes.bool,
};

export default CollapseList;
