import style from './style.module.scss';

const OpinionCircles = ({ negativeLength, positiveLength }) => (
    <div className={style['OpinionTree__panel__circle__wrapper']}>
        {positiveLength ? (
            <div className={style['OpinionTree__panel__circle__wrapper__single']}>
                <div className={style['OpinionTree__panel__circle--positive']}>
                    <i className="far fa-smile" />
                </div>
            </div>
        ) : null}
        {negativeLength ? (
            <div className={style['OpinionTree__panel__circle__wrapper__single']}>
                <div className={style['OpinionTree__panel__circle--negative']}>
                    <i className="far fa-frown" />
                </div>
            </div>
        ) : null}
    </div>
);

export default OpinionCircles;
