import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterContext } from 'screens/Report/utils/FilterContext';
import { CATEGORIES } from 'utils/showPostsBySource/showSourcesBySource';
import { sortByCount_MaxToMin } from 'utils/sorting';

import ExtraContent from './Content';
import style from './style.module.scss';

const MIN_SOURCE_COUNT = 1;

const SOURCE_NAME_TRANSLATE_MAP = { Suma: 'summary', Inne: 'other', Plik: 'file' };

const SOURCE_CATEGORY_FA = {
    Suma: 'fas fa-globe-europe',
    Twitter: 'fab fa-twitter-square',
    Facebook: 'fab fa-facebook-square',
    Inne: 'far fa-comment-dots',
    Plik: 'far fa-file',
};

const prepareClassNames = (source, activeCategory) =>
    source.category === activeCategory ? style['Source__list__container--active'] : style['Source__list__container'];

const getSourceName = (name, t) =>
    Object.keys(SOURCE_NAME_TRANSLATE_MAP).includes(name)
        ? t(`textsAndSources.textsSources.${SOURCE_NAME_TRANSLATE_MAP[name]}`)
        : name;

const SourcesBySrc = ({ sourcesTotal, sourcesTotalBySite }) => {
    sourcesTotalBySite.sort(sortByCount_MaxToMin);
    const [activeCategory, setActiveCategory] = useState(CATEGORIES.SUMMARY);
    const filterContext = useContext(FilterContext);
    const { t } = useTranslation();

    const sourcesTotalBySite_Others = sourcesTotalBySite.filter(({ category }) => category === CATEGORIES.OTHER);

    const onClick = (category) => {
        category === CATEGORIES.SUMMARY ? filterContext.setCategory(null) : filterContext.setCategory(category);
        setActiveCategory(category);
    };

    return (
        <div className={`${style['Source__container']} ${style['Source__list']}`}>
            <ul className={style['Source__list-inner']}>
                {sourcesTotal
                    .filter((source) => source.count > MIN_SOURCE_COUNT)
                    .map((source) => (
                        <li
                            onClick={() => onClick(source.category)}
                            key={source.category}
                            className={cn(
                                style.element,
                                prepareClassNames(source, activeCategory),
                                SOURCE_CATEGORY_FA[source.category],
                            )}
                        >
                            <div className={`${source.category + 'Sources'} ${style['Source__container-inner']}`}>
                                <span className={style.text}>{getSourceName(source.category, t)}</span>
                                <span className={`${style['Source__count--vertical']} ${style.text}`}>
                                    {source.count}
                                </span>
                            </div>
                        </li>
                    ))}
            </ul>

            {activeCategory === 'Inne' ? <ExtraContent data={sourcesTotalBySite_Others} /> : null}
        </div>
    );
};

SourcesBySrc.propTypes = {
    sourcesTotal: PropTypes.array.isRequired,
    sourcesTotalBySite: PropTypes.array.isRequired,
};

export default SourcesBySrc;
