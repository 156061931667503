import { useContext, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TagCloud } from 'react-tagcloud';
import { FilterContext } from 'screens/Report/utils/FilterContext';
import { useKeepSelectedDates, usePostsByDate } from 'screens/Report/utils/postDatesHooks';
import { Chart, ChartTitle, WordCloud } from 'screens/shared/style';
import { findOpinionsSortedByCount } from 'utils/postsTransformers';

import { prepareDataForCloud } from '../../utils/CloudHelper';
import CommentTooltip from '../components/CommentTooltip';
import { ExportImgButtonForChart } from '../components/ExportImgButton';
import LegendNegative from '../components/legends/LegendNegative';
import LegendNeutral from '../components/legends/LegendNeutral';
import LegendPositive from '../components/legends/LegendPositive';
import SentimentLegend from '../components/SentimentLegend';

const Legend = () => (
    <SentimentLegend>
        <LegendPositive />
        <LegendNegative />
        <LegendNeutral />
    </SentimentLegend>
);

const OpinionCloud = () => {
    const { t } = useTranslation();
    const keepSelectedDates = useKeepSelectedDates();
    const displayPosts = usePostsByDate();
    const componentRef = useRef();
    const ctx = useContext(FilterContext);

    const data = useMemo(
        () => prepareDataForCloud(displayPosts, findOpinionsSortedByCount),
        /* eslint-disable  */
        [displayPosts, ctx.state.endDate, ctx.state.startDate],
    );
    /* eslint-enable*/

    const onClick = (tag) => {
        keepSelectedDates();
        ctx.setOpinionIncludingModifier(tag);
    };

    return (
        <Chart>
            <ChartTitle>
                {t('opinions.cloud.title')}
                <CommentTooltip> {t('opinions.cloud.comment')}</CommentTooltip>
            </ChartTitle>

            <div ref={componentRef} className="bg-white">
                <WordCloud ref={componentRef}>
                    <TagCloud minSize={20} maxSize={48} tags={data} onClick={onClick} />
                    {data ? <Legend /> : null}
                </WordCloud>
            </div>

            <ExportImgButtonForChart componentRef={componentRef} />
        </Chart>
    );
};

export default OpinionCloud;
