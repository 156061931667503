import List from '@material-ui/core/List';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'screens/Report/Main/components/ReactSelector';

import NoProjectInfo from './NoProjectInfo';
import style from './style.module.scss';

const POSTFIX = '.tsv.zip';

const prepareProjectName = (projectName) => projectName.replace(POSTFIX, '');

const ProjectsList = ({ projects, handleClick, selectedProject }) => {
    const { t } = useTranslation();

    const options = projects.map((el) => ({
        value: el.filename,
        label: prepareProjectName(el.filename),
    }));

    const onClick = (selected) => {
        const project = projects.find((el) => el.filename === selected.value);
        handleClick(project);
    };

    const prepareSelectedValue = () => ({
        label: prepareProjectName(selectedProject.filename),
        value: selectedProject.filename,
    });

    const areProjects = !!projects.length;

    return (
        <>
            <div className={style.selector}>
                {!areProjects && <List component="nav" subheader={<NoProjectInfo />} />}

                {areProjects && (
                    <>
                        <div className={style.selectorLabel}>{t('selectProjectLabel')}</div>
                        <ReactSelect
                            value={selectedProject && prepareSelectedValue()}
                            onChange={onClick}
                            options={options}
                            placeholder={t('selectProject')}
                        />
                    </>
                )}
            </div>
        </>
    );
};

ProjectsList.propTypes = {
    projects: PropTypes.array.isRequired,
    handleClick: PropTypes.func.isRequired,
    selectedProject: PropTypes.object,
};

export default ProjectsList;
