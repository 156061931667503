import React from 'react';

import { PostText, StyledHighlight } from '../../../shared/style';

const REWINDING_THRESHOLD = 80;
const LEADING_CHARACTERS_BUFFER = 30;

function RewoundHighlightPostText({ post, content }) {
    const searchedPhrase = post.phraseOrth;
    const searchedPhraseStartIndex = content.indexOf(searchedPhrase);
    const rewoundContent =
        searchedPhraseStartIndex > REWINDING_THRESHOLD
            ? `(...) ${content.substr(searchedPhraseStartIndex - LEADING_CHARACTERS_BUFFER)}`
            : content;
    return (
        <PostText>
            <StyledHighlight matchClass={post.sentiment} search={searchedPhrase}>
                {rewoundContent}
            </StyledHighlight>
        </PostText>
    );
}

export default RewoundHighlightPostText;
