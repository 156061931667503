import { store } from 'index';
import * as R from 'ramda';

import { selectAccessToken } from '../state/auth/reducer';

export const API_URL = window.appConstants.API_URL;

export const headersWithAuth = (token) => ({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
});

export const callApi = async (endpoint, authenticated, unwrap = true, config = {}) => {
    if (authenticated) {
        const token = selectAccessToken(store.getState());
        if (token) {
            config = R.mergeRight({ headers: headersWithAuth(token) }, config);
        } else {
            return Promise.reject(new Error('No token saved!'));
        }
    }

    const response = await fetch(API_URL + endpoint, config);

    if (!response.ok) {
        return Promise.reject(response.status);
    }

    if (unwrap) {
        return response.json();
    }

    return response;
};
