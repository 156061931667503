import React, { Component } from 'react';

export const FilterContext = React.createContext(null);

export class FilterProvider extends Component {
    initialState = {
        aspect: null,
        opinionIncludingModifier: null,
        opinionUsingModifierAsFallback: null,
        opinionIgnoringSynonymy: null,
        topic: null,
        startDate: null,
        endDate: null,
        feature: null,
        category: null,
    };

    state = { ...this.initialState };

    render() {
        return (
            <FilterContext.Provider
                value={{
                    state: this.state,
                    setAspect: (filter) => this.setState({ aspect: filter }),
                    setOpinionUsingModifierAsFallback: (filter) =>
                        this.setState({ opinionUsingModifierAsFallback: filter }),
                    setOpinionIncludingModifier: (filter) => this.setState({ opinionIncludingModifier: filter }),
                    setTopic: (filter) => this.setState({ topic: filter }),
                    setStartDate: (filter) => this.setState({ startDate: filter }),
                    setEndDate: (filter) => this.setState({ endDate: filter }),
                    setFeature: (feature) => this.setState({ feature }),
                    setCategory: (category) => this.setState({ category }),
                    reset: () => this.setState({ ...this.initialState }),
                    setOpinionIgnoringSynonymy: (filter) => this.setState({ opinionIgnoringSynonymy: filter }),
                }}
            >
                {this.props.children}
            </FilterContext.Provider>
        );
    }
}
