import moment from 'moment';

import { GET_REPORT_FAILURE, GET_REPORT_REQUEST, GET_REPORT_SUCCESS, PARAMETRIZE_REPORT } from './actionsTypes';

const initialState = {
    posts: [],
    topics: [],
    sourcesMonths: [],
    sourcesTotals: [],
    sourcesBySite: [],
    postsYears: [],
    sentimentYears: [],
    fullPostsByDate: [],
    parameters: {},
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case PARAMETRIZE_REPORT:
            if (action.parameters.invalid) {
                return {
                    ...state,
                };
            }
            return {
                ...state,
                parameters: action.parameters,
            };
        case GET_REPORT_REQUEST:
            return {
                ...state,
            };
        case GET_REPORT_SUCCESS: {
            const { posts, topics, postsMinDate, postsMaxDate } = action.response;
            return {
                ...state,
                posts: posts.map((post) => {
                    post.moment = moment(post.date);
                    return post;
                }),
                topics,
                postsMinDate: moment(postsMinDate),
                postsMaxDate: moment(postsMaxDate),
            };
        }
        case GET_REPORT_FAILURE:
            return {
                ...state,
            };
        default:
            return state;
    }
};

export default reducer;
