import React, { useContext } from 'react';
import { PostText } from 'screens/shared/style';

import filter from '../../../model/Filters';
import { FilterContext } from '../utils/FilterContext';
import { ModalContext } from '../utils/ModalContext';
import Posts from './components/Posts';

function FlatPosts(props) {
    const { posts } = props;

    const filterContext = useContext(FilterContext);
    const modalContext = useContext(ModalContext);

    const data = filter(posts, filterContext.state);
    return (
        <Posts
            data={data}
            renderPostContent={(_, content) => <PostText>{content}</PostText>}
            onTextClick={modalContext.showFileModal}
            sentimentResolver={(_) => 'unknown'}
        />
    );
}

export default FlatPosts;
