import { login } from 'api/auth';

import { LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT_REQUEST, LOGOUT_SUCCESS } from './actionsTypes';

function requestLogin(credentials) {
    return {
        type: LOGIN_REQUEST,
        isFetching: true,
        isAuthenticated: false,
        credentials,
    };
}

function receiveLogin(user) {
    return {
        type: LOGIN_SUCCESS,
        isFetching: false,
        isAuthenticated: true,
        user: user,
    };
}

function loginError(message) {
    return {
        type: LOGIN_FAILURE,
        isFetching: false,
        isAuthenticated: false,
        message,
    };
}

export function loginUser(creds) {
    return (dispatch) => {
        dispatch(requestLogin(creds));
        return login(creds)
            .then((response) =>
                response
                    .json()
                    .then((user) => ({ user, response }))
                    .catch((error) => ({ user: { message: error.message }, response })),
            )
            .then(({ user, response }) => {
                if (!response.ok) {
                    dispatch(loginError(user.message));
                    return Promise.reject(user);
                } else {
                    localStorage.setItem('user', JSON.stringify(user));
                    dispatch(receiveLogin(user));
                }
            })
            .catch((err) => console.log('Error: ', err));
    };
}

function requestLogout() {
    return {
        type: LOGOUT_REQUEST,
        isFetching: true,
        isAuthenticated: true,
    };
}

function receiveLogout() {
    return {
        type: LOGOUT_SUCCESS,
        isFetching: false,
        isAuthenticated: false,
    };
}

export function logoutUser() {
    return (dispatch) => {
        dispatch(requestLogout());
        localStorage.removeItem('user');
        dispatch(receiveLogout());
    };
}
