import React from 'react';
import { useTranslation } from 'react-i18next';
import { PostAreaHeader, PostAreaTitle } from 'screens/shared/style';

import DatePickerPanel from './DatePickerPanel';
import FiltersPanel from './FiltersPanel';
import FullSearch from './FullSearch';
import TopicSelectorPanel from './TopicSelectorPanel';

const PostsTitle = ({ handleSearchByAnyText }) => {
    const { t } = useTranslation();

    return (
        <PostAreaHeader>
            <i className="far fa-comments" />
            <PostAreaTitle>{t('postsList.speeches')}</PostAreaTitle>
            <DatePickerPanel />
            <TopicSelectorPanel />
            <FiltersPanel />
            <FullSearch handleSearchByAnyText={handleSearchByAnyText} />
        </PostAreaHeader>
    );
};

export default PostsTitle;
