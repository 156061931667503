import jwt_decode from 'jwt-decode';
import moment from 'moment';

export function isTokenExpired(tokenBase64) {
    try {
        const token = jwt_decode(tokenBase64);
        const now = moment();
        return now.isAfter(moment.unix(token.exp));
    } catch (e) {
        console.log(e);
        return true;
    }
}
