import { callApi } from '../../api/constants';
import { logoutUser } from '../auth/actions';

export const CALL_API = Symbol('Call API');

const handleApi = (store) => (next) => (action) => {
    if (isNotApiAction(action)) {
        return next(action);
    }

    let { endpoint, types, authenticated, config } = action[CALL_API];

    /* eslint-disable */
    const [requestType, successType, errorType] = types;
    /* eslint-enable  */

    return callApi(endpoint, authenticated, true, config).then(
        (response) =>
            next({
                response,
                authenticated,
                type: successType,
            }),
        (error) => {
            if (error === 401) {
                store.dispatch(logoutUser());
            }
            return next({
                error: error.message || 'Ups, gdzieś wkradł się błąd.',
                type: errorType,
            });
        },
    );
};

export default handleApi;

function isNotApiAction(action) {
    const callAPI = action[CALL_API];
    return typeof callAPI === 'undefined';
}
