export const prepareDatasets = (sentiments, colors, positive, negative, neutral, unknown) => [
    {
        data: [positive],
        maxBarThickness: 40,
        label: sentiments[0],
        backgroundColor: colors[1],
    },
    {
        data: [negative],
        maxBarThickness: 40,
        label: sentiments[1],
        backgroundColor: colors[2],
    },
    {
        data: [neutral],
        maxBarThickness: 40,
        label: sentiments[2],
        backgroundColor: colors[3],
    },
    {
        data: [unknown],
        maxBarThickness: 40,
        label: sentiments[3],
        backgroundColor: colors[4],
    },
];

export const countData = (filtered) => {
    let positive = 0;
    let negative = 0;
    let neutral = 0;
    let unknown = 0;

    filtered.forEach((post) => {
        switch (post.sentiment) {
            case 'pozytywny':
                positive++;
                break;
            case 'negatywny':
                negative++;
                break;
            case 'neutralny':
                neutral++;
                break;
            case 'nieznany':
                unknown++;
                break;
            default:
                break;
        }
    });

    return { positive, negative, neutral, unknown };
};
