const sortNumber = (a, b) => a - b;

export const mapSourcesByDate = (posts, dates, key) =>
    dates.map((date) => ({
        date,
        data: posts.filter((post) => post[key] === date),
    }));

export const prepareDatesLabels = (posts, key) => {
    const dates = posts.map((post) => post[key]);
    const uniqueDates = Array.from(new Set(dates))
        .map((el) => parseInt(el, 10))
        .filter((el) => !isNaN(el)); //TODO raport tak pokazuje np wino
    uniqueDates.sort(sortNumber);
    return uniqueDates.map((el) => el.toString());
};

const getLabel = (element) => (isNaN(element.page) ? 'page' : 'category');

export const countDataByLabel = (dataSet, labelMapper = getLabel) => {
    const counter = {};

    for (const element of dataSet) {
        const key = labelMapper(element);
        const data = element[key];
        counter[data] = counter[data] ? counter[data] + 1 : 1;
    }

    return counter;
};
