import React, { useState } from 'react';

import { fetchText } from '../../../../api/texts';
import { NOT_FOUND } from '../../../../model/ReportConstants';
import { PostAddress, PostContent, PostSentimentList } from '../../../shared/style';
import CopyTextButton from './CopyTextButton';
import FetchTextButton from './FetchTextButton';
import css from './style.module.scss';

const TIME_TO_REMOVE_TOAST_MS = 5000;

const extractContent = (post) =>
    post.content !== undefined && post.content !== NOT_FOUND ? post.content : post.context;

const extractLinkToFullText = (projectName, post) =>
    `/projects/${projectName}/texts/${post.filename.replace('./', '')}.txt`;

const Post = ({ post, renderContent, sentiment, style, onTextClick, projectName }) => {
    const [content] = useState(extractContent(post));
    const [showToast, setShowToast] = useState(false);
    const onCopy = () => fetchText(extractLinkToFullText(projectName, post), copyToClipBoard);

    const copyToClipBoard = async (text) => {
        await navigator.clipboard.writeText(text);
        setShowToast(true);
        setTimeout(() => setShowToast(false), TIME_TO_REMOVE_TOAST_MS);
    };

    return (
        <div style={style}>
            <PostContent>
                <PostSentimentList className={sentiment}>
                    <span>{post.date}</span>
                    {showToast && <span className={css['CopiedText']}>Skopiowano do schowka!</span>}
                    <FetchTextButton onClick={() => fetchText(extractLinkToFullText(projectName, post), onTextClick)} />
                    <CopyTextButton onFetch={onCopy} />
                </PostSentimentList>
                <PostAddress href={post.isValidLink ? post.link : '#'} target="_blank">
                    {post.link !== 'null' ? post.link : ''}
                </PostAddress>
                {renderContent(post, content)}
            </PostContent>
        </div>
    );
};

export default Post;
