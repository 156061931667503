import * as R from 'ramda';

const nullable = (suspect, predicate) => (suspect === null || suspect === undefined ? true : predicate(suspect));

const aspect = (filters) => ({ aspectBase }) => {
    return nullable(filters.aspect, (suspect) => aspectBase === suspect);
};

const sentimentWord = (filters) => ({ sentimentWord }) => {
    return nullable(filters.sentimentWord, (suspect) => suspect === sentimentWord);
};

const modifierWord = (filters) => ({ modifierWord }) => {
    return nullable(filters.modifierWord, (suspect) => suspect === modifierWord);
};

const sentiment = (filters) => ({ sentiment }) => {
    return nullable(filters.sentiment, (suspect) => suspect === sentiment);
};

const topic = (filters) => ({ topic }) => {
    return nullable(filters.topic, (suspect) => suspect === topic);
};

const startDate = (filters) => ({ moment }) => {
    return nullable(filters.startDate, (suspect) => moment.isSameOrAfter(suspect));
};

const endDate = (filters) => ({ moment }) => {
    return nullable(filters.endDate, (suspect) => moment.isSameOrBefore(suspect));
};

const category = (filters) => ({ category }) => {
    return nullable(filters.category, (suspect) => suspect === category);
};

const originalSentimentWord = (filters) => ({ originalSentimentWord, sentimentWord }) => {
    return nullable(filters.originalSentimentWord, (suspect) =>
        originalSentimentWord ? suspect === originalSentimentWord : suspect === sentimentWord,
    );
};

const opinionWithOriginalSentiment = ({ opinionIgnoringSynonymy }) => (text) => {
    if (opinionIgnoringSynonymy) {
        const filter = R.allPass([
            originalSentimentWord(opinionIgnoringSynonymy),
            modifierWord(opinionIgnoringSynonymy),
            sentiment(opinionIgnoringSynonymy),
        ]);
        return filter(text);
    }
    return true;
};

const opinionIncludingModifier = ({ opinionIncludingModifier }) => (text) => {
    if (opinionIncludingModifier) {
        const filter = R.allPass([
            sentimentWord(opinionIncludingModifier),
            modifierWord(opinionIncludingModifier),
            sentiment(opinionIncludingModifier),
        ]);
        return filter(text);
    }
    return true;
};

const opinionUsingModifierAsFallback = ({ opinionUsingModifierAsFallback }) => (text) => {
    if (opinionUsingModifierAsFallback) {
        const opinionTransmitter = opinionUsingModifierAsFallback.sentimentWord
            ? sentimentWord(opinionUsingModifierAsFallback)
            : modifierWord(opinionUsingModifierAsFallback);
        const filter = R.allPass([opinionTransmitter, sentiment(opinionUsingModifierAsFallback)]);
        return filter(text);
    }
    return true;
};

const feature = (filters) => (text) => {
    if (filters.feature) {
        const filter = filters.feature.sentimentWord
            ? R.allPass([sentimentWord(filters.feature)])
            : R.allPass([modifierWord(filters.feature), sentimentWord({ sentimentWord: '' })]);
        return filter(text);
    }
    return true;
};

function combinePredicates(filters) {
    return R.allPass([
        opinionWithOriginalSentiment(filters),
        originalSentimentWord(filters),
        opinionIncludingModifier(filters),
        opinionUsingModifierAsFallback(filters),
        aspect(filters),
        sentimentWord(filters),
        feature(filters),
        topic(filters),
        startDate(filters),
        endDate(filters),
        category(filters),
    ]);
}

function filter(posts, filters) {
    const satisfiesAllFilters = combinePredicates(filters);
    return posts.filter(satisfiesAllFilters);
}

export default filter;
