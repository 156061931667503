import Select from 'react-select';

import style from '../../../../public/styles/_theme.scss';

const CategoryDropdown = ({ selectedCategory, onChange, options }) => (
    <Select
        value={selectedCategory}
        onChange={onChange}
        options={options}
        theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            color: style.colorGreen,
            fontFamily: 'Oswald',
            colors: {
                ...theme.colors,
                primary25: style.colorGreyLighter,
                primary: '#5dc24a',
            },
        })}
        styles={{
            option: (provided) => ({
                ...provided,
                borderBottom: '1px dotted',
                fontSize: '16px',
                fontWeight: '200',
                cursor: 'pointer',
            }),
            control: (provided) => ({
                ...provided,
                width: '100px',
                fontSize: '16px',
                fontWeight: '200',
                height: '46px',
                borderStyle: 'none',
                borderBottom: '2px',
                borderBottomColor: 'transparent',
                cursor: 'pointer',
            }),
            container: (provided) => ({
                ...provided,
                display: 'inline-block',
            }),
            menu: (provided) => ({
                ...provided,
                zIndex: '1001',
            }),
            singleValue: (provided, state) => {
                const opacity = state.isDisabled ? 0.5 : 1;
                const transition = 'opacity 300ms';

                return { ...provided, opacity, transition, color: style.colorGreenSecondary };
            },
        }}
    />
);

export default CategoryDropdown;
