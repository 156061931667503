import React from 'react';
import Select from 'react-select';

import appTheme from '../../../../../public/styles/_theme.scss';

const customStyles = {
    option: (provided) => ({
        ...provided,
        borderBottom: '1px dotted',
        fontSize: appTheme.fontSize,
        cursor: 'pointer',
        color: 'black',
        '&:last-of-type': {
            border: '0 !important',
        },
    }),
    control: (provided) => ({
        ...provided,
        fontSize: appTheme.fontSize,
        height: '46px',
        borderStyle: 'none',
        borderBottom: '2px',
        borderBottomColor: 'transparent',
        cursor: 'pointer',
        color: 'black !important',
    }),
    singleValue: (provided, state) => ({
        ...provided,
        opacity: state.isDisabled ? 0.5 : 1,
        transition: 'opacity 300ms',
        color: appTheme.colorGreyDarkDark,
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
    }),
};

const customTheme = (theme) => ({
    ...theme,
    borderRadius: 0,
    fontFamily: appTheme.fontFamily,
    colors: {
        ...theme.colors,
        primary25: appTheme.colorGreyLighter,
        primary: appTheme.colorGreen,
    },
});

const ReactSelect = (props) => (
    <Select
        {...props}
        theme={customTheme}
        styles={customStyles}
        // below to fix z-index menu problem,
        // https://github.com/JedWatson/react-select/issues/1537#issuecomment-732314650
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
    />
);

export default ReactSelect;
