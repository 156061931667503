import { CALL_API } from '../middleware/api';
import { GET_REPORT_FAILURE, GET_REPORT_REQUEST, GET_REPORT_SUCCESS, PARAMETRIZE_REPORT } from './actionsTypes';

export const parametrizeReport = (parameters, setCookie) => (dispatch) => {
    parameters = { ...parameters, refreshed: new Date(Date.now()).toLocaleString() };
    setCookie(parameters);
    return Promise.resolve(
        dispatch({
            type: PARAMETRIZE_REPORT,
            parameters,
        }),
    );
};

export const getReport = (projectName, reportId) => ({
    [CALL_API]: {
        endpoint: `/projects/${reportId}/bundles/${projectName}.tsv.zip/report`,
        authenticated: true,
        types: [GET_REPORT_REQUEST, GET_REPORT_SUCCESS, GET_REPORT_FAILURE],
    },
});
