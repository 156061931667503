import 'moment/locale/pl';

import * as moment from 'moment';
import { useContext, useState } from 'react';
import { DateRangePicker } from 'react-dates';
import { useSelector } from 'react-redux';
import { useKeepSelectedDates } from 'screens/Report/utils/cloudHooks';

import { FilterContext } from '../../../utils/FilterContext';
import { DateRangePickerPhrases, endDatePlaceholderText, startDatePlaceholderText } from './datePickerPhrases';
import MonthsAndYearSelector from './MonthsAndYearSelector';
import style from './style.module.scss';

moment.locale('pl');

const DatePickerPanel = () => {
    const context = useContext(FilterContext);
    const keepSelectedDates = useKeepSelectedDates();
    const minDate = useSelector((state) => state.report.postsMinDate);
    const maxDate = useSelector((state) => state.report.postsMaxDate);
    const [focusedInput, setFocusedInput] = useState(null);

    const onClick = ({ startDate, endDate }) => {
        keepSelectedDates();
        context.setStartDate(startDate);
        context.setEndDate(endDate);
    };

    // not showing current selected dates: https://github.com/airbnb/react-dates/issues/1845, still open
    return (
        <div className={style['DatePicker__container']}>
            <DateRangePicker
                daySize={48}
                renderMonthElement={(props) => <MonthsAndYearSelector {...props} minDate={minDate} maxDate={maxDate} />}
                startDate={context.state.startDate}
                endDate={context.state.endDate}
                startDateId="unique_start_date_id"
                minDate={minDate}
                maxDate={maxDate}
                endDateId="unique_end_date_id"
                minimumNights={0}
                initialVisibleMonth={() => minDate}
                isOutsideRange={(day) => day.isSameOrAfter(maxDate) || day.isSameOrBefore(minDate)}
                onDatesChange={onClick}
                focusedInput={focusedInput}
                onFocusChange={setFocusedInput}
                showClearDates
                withPortal
                noBorder
                phrases={DateRangePickerPhrases}
                startDatePlaceholderText={startDatePlaceholderText}
                endDatePlaceholderText={endDatePlaceholderText}
            />
        </div>
    );
};

export default DatePickerPanel;
