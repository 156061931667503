import * as PropTypes from 'prop-types';
import React from 'react';

import style from './style.module.scss';

const TypeBasedTitle = ({ type, children, onClick }) => {
    const cssType = ['positive', 'negative', 'neutral'].indexOf(type) > -1 ? type : 'unknown';
    const className = `SelectList__title--${cssType}`;

    return (
        <div className={`${style['SelectList__title']} ${style[className]}`} onClick={onClick}>
            {children}
        </div>
    );
};

TypeBasedTitle.propTypes = {
    type: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default TypeBasedTitle;
