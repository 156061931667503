import React from 'react';
import { connect } from 'react-redux';
import { ChartArea } from 'screens/shared/style';

import OpinionTree from './OpinionTree';

const OpinionTreePanel = () => {
    return (
        <ChartArea>
            <OpinionTree />
        </ChartArea>
    );
};

export default connect(null, null)(OpinionTreePanel);
