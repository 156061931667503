import { IGNORED, NOT_FOUND, UNKNOWN } from '../model/ReportConstants';
import Colors from './Colors';
import { sortByCount_desc } from './sorting';

export const MIN_DISPLAY_COUNT = 1;
export const MAX_COUNT = 50;

const LOG_BASE = 2;
const DEFAULT_TRESHOLD = 0.06;
const MIN_WORDS_IN_CLOUD = 10;
const DEFAULT_SENTIMENT = 'sentimentWord';

const filterRelevantResults = (data, totalCount, threshold = DEFAULT_TRESHOLD) =>
    data.filter((result) => {
        const weight = (result.count * 100) / totalCount;
        return weight > threshold;
    });

const getTotalCount = (allResults) => allResults.reduce((accumulator, item) => accumulator + item.count, 0);

const processCount = (count, allPostsCount) => {
    if (allPostsCount > MAX_COUNT) {
        const log = Math.log(count) / Math.log(LOG_BASE);
        return Math.ceil(log);
    } else {
        return count;
    }
};

const countAspectFrequency = (posts) => {
    const frequency = {};
    posts.forEach((post) => {
        const word = post.aspectBase;
        if (word !== '' && word !== NOT_FOUND && word !== IGNORED) {
            word in frequency ? (frequency[word] += 1) : (frequency[word] = 1);
        }
    });
    return frequency;
};

const countOpinionFrequency = (posts, checkUnknownSetiment = true, sentimentWord = 'sentimentWord') => {
    const frequency = {};

    posts.forEach((post) => {
        const modifier = post.modifierWord;
        const carrier = post[sentimentWord] || post[DEFAULT_SENTIMENT];
        const sentiment = post.sentiment;
        const checkSentiment = checkUnknownSetiment ? sentiment !== UNKNOWN : true;

        if (carrier !== '' && carrier !== NOT_FOUND && checkSentiment) {
            let phrase = carrier;

            if (modifier !== '' && modifier !== NOT_FOUND) {
                phrase = `${modifier} ${phrase}`;
            }

            if (!(phrase in frequency)) {
                frequency[phrase] = {};
            }

            if (!(sentiment === frequency[phrase].sentiment)) {
                frequency[phrase].count = 0;
                frequency[phrase].sentiment = sentiment;
                frequency[phrase][sentimentWord] = carrier;
                frequency[phrase].modifierWord = modifier;
                frequency[phrase].phrase = phrase;
            }

            if (frequency[phrase].sentiment === sentiment) {
                frequency[phrase].count += 1;
            }
        }
    });

    return frequency;
};

export const findAspectsSortedByCountMoreThanDefaultCount = (posts, minDisplayCount = MIN_DISPLAY_COUNT) => {
    const frequency = countAspectFrequency(posts);

    const allResults = Object.keys(frequency)
        .filter((key) => frequency[key] > minDisplayCount)
        .map((key) => ({
            rawCountBeforeProcess: frequency[key],
            count: processCount(frequency[key], posts.length),
            color: '#000000',
            value: key,
        }));

    const totalCount = getTotalCount(allResults);
    return filterRelevantResults(allResults, totalCount);
};

export const findAspectsSortedByCount = (posts) => {
    const frequency = countAspectFrequency(posts);
    return Object.keys(frequency)
        .map((key) => ({
            count: frequency[key],
            value: key,
        }))
        .sort(sortByCount_desc);
};

const prepareOpinionData = (key, frequency, allPostsCount) => ({
    ...frequency[key],
    rawCountBeforeProcess: frequency[key].count,
    count: processCount(frequency[key].count, allPostsCount),
    color: Colors.mapSentiment(frequency[key].sentiment),
    value: key,
    sentiment: frequency[key].sentiment,
});

export const findOpinionsSortedByCount_fullEqual = (posts, key = 'originalSentimentWord') => {
    const frequency = countOpinionFrequency(posts, false, key);
    const allResults = Object.keys(frequency).map((key) => prepareOpinionData(key, frequency));

    return allResults;
};

export const findOpinionsSortedByCount = (posts, minDisplayCount = MIN_DISPLAY_COUNT) => {
    const frequency = countOpinionFrequency(posts);
    const allResults = Object.keys(frequency)
        .filter((key) => frequency[key].count > minDisplayCount)
        .map((key) => prepareOpinionData(key, frequency, posts.length));

    const totalCount = getTotalCount(allResults);

    return totalCount < MIN_WORDS_IN_CLOUD ? allResults : filterRelevantResults(allResults, totalCount, 0.1);
};
