import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { usePostsByDate } from 'screens/Report/utils/cloudHooks';
import { Chart, ChartTitle } from 'screens/shared/style';
import { prepareOpinionsForTopic } from 'utils/graphPreparation.js';

import { ExportImgButtonForChart } from '../components/ExportImgButton';
import FeatureBalls from './Balls/FeatureBalls';
import OpinionBalls from './Balls/OpinionBalls';
import CategoryDropdown from './CategoryDropdown';

const getFilters = (value) => {
    if (value === 'Opinie') {
        return ['pozytywny', 'negatywny', 'neutralny'];
    }

    if (value === 'Cechy') {
        return ['nieznany'];
    }

    return [];
};

const TopicWithOpinions = ({ aspect }) => {
    const { t } = useTranslation();

    const OPTIONS = [
        { value: 'Opinie', label: t('topics.withOpinion.opinions') },
        { value: 'Cechy', label: t('topics.withOpinion.features') },
    ];

    const posts = useSelector((state) => state.report.posts);
    const postsByDate = usePostsByDate(posts);
    const [selectedCategory, setSelectedCategory] = useState(OPTIONS[0]);
    const data = aspect ? prepareOpinionsForTopic(postsByDate, aspect, getFilters(selectedCategory.value)) : null;
    const componentRef = useRef();

    return (
        <Chart>
            <div className="bg-white pb-3" ref={componentRef}>
                <ChartTitle>
                    <CategoryDropdown
                        selectedCategory={selectedCategory}
                        onChange={(option) => setSelectedCategory(option)}
                        options={OPTIONS}
                    />
                    {t('topics.withOpinion.forTopic')} <strong>{aspect}</strong>
                </ChartTitle>
                <div>
                    {selectedCategory.value === 'Opinie' && <OpinionBalls data={data} aspect={aspect} />}
                    {selectedCategory.value === 'Cechy' && <FeatureBalls data={data} aspect={aspect} />}
                </div>
            </div>
            {data && <ExportImgButtonForChart componentRef={componentRef} />}
        </Chart>
    );
};

export default TopicWithOpinions;
