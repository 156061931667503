import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useKeepSelectedDates, usePostsByDate } from 'screens/Report/utils/postDatesHooks';
import { Chart, ChartTitle } from 'screens/shared/style';

import { FilterContext } from '../../utils/FilterContext';
import CommentTooltip from '../components/CommentTooltip';
import Selector from '../components/Selector';
import { prepareListsData } from '../components/Selector/listsPreparation';

const OpinionSelector = () => {
    const { t } = useTranslation();
    const displayPosts = usePostsByDate();
    const data = prepareListsData(displayPosts);
    const filterContext = useContext(FilterContext);
    const keepSelectedDates = useKeepSelectedDates();

    const onClick = useCallback(
        (opinion) => {
            keepSelectedDates();
            filterContext.setOpinionIncludingModifier(opinion);
            filterContext.setOpinionIgnoringSynonymy({
                modifierWord: opinion.modifierWord,
                phrase: opinion.value,
                sentiment: opinion.sentiment,
                originalSentimentWord: opinion.originalSentimentWord,
                value: opinion.value,
            });
        },
        [filterContext, keepSelectedDates],
    );

    const onClickUnknown = useCallback(
        (entry) => {
            keepSelectedDates();
            filterContext.setFeature({
                sentimentWord: entry.value,
                modifierWord: undefined,
            });
        },
        [filterContext, keepSelectedDates],
    );

    const collapseData = useMemo(
        () => [
            {
                type: 'positive',
                header: t('opinions.selector.opinions.positive'),
                data: data.positive,
                onClick,
            },
            {
                type: 'negative',
                header: t('opinions.selector.opinions.negative'),
                data: data.negative,
                onClick,
            },
            {
                type: 'neutral',
                header: t('opinions.selector.opinions.neutral'),
                data: data.neutral,
                onClick,
            },
            {
                type: 'unknown',
                header: t('opinions.selector.opinions.unknown'),
                data: data.unknown,
                onClick: onClickUnknown,
            },
        ],
        [data.negative, data.neutral, data.positive, data.unknown, onClick, onClickUnknown, t],
    );

    return (
        <Chart>
            <ChartTitle>
                {t('opinions.selector.title')}
                <CommentTooltip> {t('opinions.selector.comment')}</CommentTooltip>
            </ChartTitle>
            <Selector data={collapseData} />
        </Chart>
    );
};

export default OpinionSelector;
