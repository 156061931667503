import React from 'react';

import logo from '../../assets/img/logo-white.png';
import style from './style.module.scss';

const Logo = () => (
    <div className={style['Browser__logo__wrapper']}>
        <img src={logo} alt="Aspkety logo" className={style['Browser__logo']} />
    </div>
);

const Container = (props) => (
    <div className={style['Browser__container']}>
        <div className="p-relative">
            <Logo />
            <div className={style['Browser__report-container']}>
                {props.isSelector && (
                    <div className={style['selector__wrapper']}>{!props.errorMessage && props.children}</div>
                )}
                {!props.isSelector && !props.errorMessage && props.children}
            </div>
        </div>
        <div>
            <p style={{ color: 'red' }}>{props.errorMessage}</p>
        </div>
    </div>
);

export default Container;
