import React from 'react';
import { FilterContext } from 'screens/Report/utils/FilterContext';
import styled from 'styled-jss';

import style from './style.module.scss';

const ActiveFilters = styled('ul')({
    listStyle: ' none',
    margin: ' 0',
    overflow: ' hidden',
    padding: ' 0',
    paddingTop: ' .5rem',
});

const FilterLabel = styled('li')({
    float: 'left',
    fontFamily: 'roboto',
    fontSize: '14px',
    background: 'rgb(86, 172, 70)',
    borderRadius: '3px 0 0 3px',
    color: '#fff',
    display: 'inline-block',
    height: '26px',
    lineHeight: '26px',
    padding: '0 20px 0 0px',
    position: 'relative',
    margin: '0 10px 10px 0',
    '-webkit-transition': 'color 0.2s',

    '&:after': {
        background: 'rgb(248, 248, 248)',
        borderBottom: '13px',
        borderBottomStyle: 'solid',
        borderBottomColor: 'transparent',
        borderLeft: '10px',
        borderLeftStyle: 'solid',
        borderLeftColor: 'rgb(86, 172, 70)',
        borderTop: '13px',
        borderTopStyle: 'solid',
        borderTopColor: 'transparent',
        content: "''",
        position: 'absolute',
        right: '0',
        top: '0',
    },
    '&:hover': {
        backgroundColor: 'red',
        color: 'white',
        cursor: 'pointer',
        '&:after': {
            borderLeftColor: 'red',
        },
        '&:before': {
            backgroundColor: 'red',
        },
    },
});

const FilterLabelWithIcon = ({ onClick, children }) => (
    <FilterLabel onClick={onClick}>
        <i className={`fa fa-times ${style['FiltersPanel__icon']}`} />
        {children}
    </FilterLabel>
);

function FiltersPanel() {
    return (
        <FilterContext.Consumer>
            {(context) => (
                <ActiveFilters>
                    {context.state.aspect && (
                        <FilterLabelWithIcon onClick={() => context.setAspect(null)}>
                            wątek:{context.state.aspect}
                        </FilterLabelWithIcon>
                    )}
                    {context.state.category && (
                        <FilterLabelWithIcon onClick={() => context.setCategory(null)}>
                            źródło:{context.state.category}
                        </FilterLabelWithIcon>
                    )}
                    {context.state.feature && (
                        <FilterLabelWithIcon onClick={() => context.setFeature(null)}>
                            cecha:{context.state.feature.sentimentWord || context.state.feature.modifierWord}
                        </FilterLabelWithIcon>
                    )}
                    {context.state.opinionIncludingModifier && (
                        <FilterLabelWithIcon onClick={() => context.setOpinionIncludingModifier(null)}>
                            opinia:{context.state.opinionIncludingModifier.phrase}
                        </FilterLabelWithIcon>
                    )}
                    {context.state.opinionIgnoringSynonymy && (
                        <FilterLabelWithIcon onClick={() => context.setOpinionIgnoringSynonymy(null)}>
                            opinia:{context.state.opinionIgnoringSynonymy.phrase}
                        </FilterLabelWithIcon>
                    )}
                    {context.state.opinionUsingModifierAsFallback && (
                        <FilterLabelWithIcon onClick={() => context.setOpinionUsingModifierAsFallback(null)}>
                            opinia:{context.state.opinionUsingModifierAsFallback.phrase}
                        </FilterLabelWithIcon>
                    )}
                </ActiveFilters>
            )}
        </FilterContext.Consumer>
    );
}

export default FiltersPanel;
