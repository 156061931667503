import { CALL_API } from '../middleware/api';
import { GET_PROJECTS_FAILURE, GET_PROJECTS_REQUEST, GET_PROJECTS_SUCCESS } from './actionsTypes';

export const getProjects = () => ({
    [CALL_API]: {
        endpoint: '/projects',
        authenticated: true,
        types: [GET_PROJECTS_REQUEST, GET_PROJECTS_SUCCESS, GET_PROJECTS_FAILURE],
    },
});
