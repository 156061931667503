import Highlight from 'react-highlighter';
import styled from 'styled-jss';

export const ChartArea = styled('div')({
    width: '100%',
});

export const Chart = styled('div')({
    background: '#ffffff',
    width: '98%',
    fontWeight: 'normal',
    border: 'solid 1px #cccccc',
    marginBottom: '20px',
    position: 'relative',
});

export const ChartBody = styled('div')({
    marginTop: '10px',
    height: '300px',
});

export const ChartBodyLine = styled('div')({
    marginTop: '10px',
    height: '300px',
    paddingBottom: '100px',
});

export const ChartTitle = styled('div')({
    padding: '2% 2% 0 2%',
    color: '#56ac46',
    fontFamily: 'Oswald',
    marginBottom: '15px',
});

export const PostAreaHeader = styled('div')({
    margin: '1% 0 3% 0',
    display: 'block',
});

export const PostAreaTitle = styled('span')({
    fontFamily: 'Saira Condensed',
    paddingLeft: '4%',
});

export const PostContent = styled('div')({
    background: '#ffffff',
    padding: '0 2% 2px 2%',
    margin: '0 15px 5px 0',
    border: '1px solid #ebebeb',
    textOverflow: 'ellipsis',
    position: 'relative',
});

export const PostText = styled('p')({
    color: '#404040',
    fontSize: '14px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
});

export const PostAddress = styled('a')({
    fontSize: '12px',
    fontWeight: '600',
    marginTop: '-10px',
    wordWrap: 'break-word',
    display: 'block',
    color: '#404040',
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
});

export const StyledHighlight = styled(Highlight)({
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: '#404040',
    fontSize: '12px',
    '& mark': {
        backgroundColor: 'white',
    },
    '& mark.nieznany': {
        color: '#8a8a8a',
    },
    '& mark.pozytywny': {
        color: '#5dc24a',
    },
    '& mark.negatywny': {
        color: '#ff514e',
    },
    '& mark.neutralny': {
        color: '#79bfd9',
    },
});

export const PostSentimentDiv = styled('div')({
    marginTop: '-5px',
    listStylePosition: 'inside',
    listStyleType: 'none',
    fontSize: '12px',
    lineHeight: '32px',
    color: '#404040',
    '& span': {
        color: '#8a8a8a',
    },
    '&:before': {
        position: 'relative',
        top: '9px',
        content: '"• "',
        fontSize: '32px',
        lineHeight: '10px',
    },
    '&.nieznany': {
        color: '#8a8a8a',
    },
    '&.pozytywny': {
        color: '#5dc24a',
    },
    '&.negatywny': {
        color: '#ff514e',
    },
    '&.neutralny': {
        color: '#79bfd9',
    },
});

export const PostSentimentList = styled('li')({
    marginTop: '-5px',
    listStylePosition: 'inside',
    listStyleType: 'none',
    fontSize: '12px',
    lineHeight: '32px',
    color: '#404040',
    '& span': {
        color: '#8a8a8a',
    },
    '&:before': {
        position: 'relative',
        top: '9px',
        content: '"• "',
        fontSize: '32px',
        lineHeight: '10px',
    },
    '&.nieznany': {
        color: '#8a8a8a',
    },
    '&.pozytywny': {
        color: '#5dc24a',
    },
    '&.negatywny': {
        color: '#ff514e',
    },
    '&.neutralny': {
        color: '#79bfd9',
    },
});

export const ChartIconArea = styled('div')({
    position: 'relative',
    float: 'right',
    width: '36px',
    height: '36px',
    background: '#56ac46',
    borderRadius: '50%',
    '&:hover span': {
        visibility: 'visible',
    },
});

export const ChartTooltip = styled('span')({
    visibility: 'hidden',
    backgroundColor: 'white',
    color: 'rgb(138, 138, 138)',
    textAlign: 'center',
    padding: '2px 0',
    border: '1px solid #ebebeb',
    borderRadius: '6px',
    position: 'absolute',
    left: '-50px',
    top: '40px',
    zIndex: '2',
    width: '150px',
    fontSize: '12px',
    fontWeight: 'normal',
});

export const WordCloud = styled('div')({
    padding: '2%',
    borderRadius: '50%',
    textAlign: 'center',
});
