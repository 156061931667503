import PropTypes from 'prop-types';
import React from 'react';
import { exportComponentAsPNG } from 'react-component-export-image';
import { useTranslation } from 'react-i18next';

import style from './style.module.scss';

const ExportImgButton = ({ componentRef }) => {
    const { t } = useTranslation();

    return (
        <button className="button" onClick={() => exportComponentAsPNG(componentRef)}>
            {t('export')} <i className="fas fa-download" />
        </button>
    );
};

ExportImgButton.propTypes = {
    componentRef: PropTypes.any.isRequired,
};

export default ExportImgButton;

export const ExportImgButtonForChart = ({ componentRef }) => (
    <div className={style['ExportImgButtonForChart']}>
        <ExportImgButton componentRef={componentRef} />
    </div>
);
