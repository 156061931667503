import React, { useContext, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TagCloud } from 'react-tagcloud';
import { FilterContext } from 'screens/Report/utils/FilterContext';
import { useKeepSelectedDates, usePostsByDate } from 'screens/Report/utils/postDatesHooks';
import { Chart, ChartTitle, WordCloud } from 'screens/shared/style';
import { findAspectsSortedByCountMoreThanDefaultCount } from 'utils/postsTransformers';

import { prepareDataForCloud } from '../../utils/CloudHelper';
import CommentTooltip from '../components/CommentTooltip';
import { ExportImgButtonForChart } from '../components/ExportImgButton';

const TopicCloud = () => {
    const componentRef = useRef();
    const keepSelectedDates = useKeepSelectedDates();
    const displayPosts = usePostsByDate();
    const ctx = useContext(FilterContext);
    const { t } = useTranslation();

    const onClick = (tag) => {
        keepSelectedDates();
        ctx.setAspect(tag.value);
    };

    const data = useMemo(
        () => prepareDataForCloud(displayPosts, findAspectsSortedByCountMoreThanDefaultCount),
        /* eslint-disable  */
        [displayPosts, ctx.state.endDate, ctx.state.startDate],
    );
    /* eslint-enable*/

    return (
        <Chart>
            <ChartTitle>
                {t('topics.cloud.title')}
                <CommentTooltip> {t('topics.cloud.comment')}</CommentTooltip>
            </ChartTitle>
            <div ref={componentRef} className="bg-white">
                <WordCloud>
                    <TagCloud minSize={16} maxSize={56} tags={data} onClick={onClick} />
                </WordCloud>
            </div>
            <ExportImgButtonForChart componentRef={componentRef} />
        </Chart>
    );
};

export default TopicCloud;
